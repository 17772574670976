import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TEsiaParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { isObjectEmpty } from '../../helpers';
import { TChips } from './CreateProvider';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { LicenseSelect } from '../applications/LicenseSelect';
import { SharedFormProvider } from '../shared/FormProvider';
import { ConfirmationModal } from '../modal/ConfirmationModal';

export type EditEsiaProviderInputs = {
  name: string;
  description: string;
  external_client_id: string;
  scopes: string;
  avatar: File | null;
  path_to_avatar: string;
  provider_id: string;
  auto_registration: boolean;
  is_public: boolean;
  // mapping: string;
  issuer: string;
  certificate: string;
  sign_endpoint: string;
  verify_endpoint: string;
  // authorization_endpoint: string;
  // token_endpoint: string;
  // userinfo_endpoint: string;
};

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  authorization_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  token_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  userinfo_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  sign_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  verify_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  certificate: yup.string().required('Обязательное поле'),
  auto_registration: yup.boolean(),
  is_public: yup.boolean(),
  mapping: yup.string().max(2000, 'Mapping не может превышать 2000 символов'),
});

export const EditEsiaProvider: FC<
  TEditProvider<
    TMiscProvider & {
      params: TEsiaParams;
      provider_id: string;
      avatar?: File | null;
    }
  >
> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditEsiaProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [chips, setChips] = useState<TChips[]>([]);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
      setChips([]);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as ArrayBuffer;
        const base64Content = arrayBufferToBase64(content);
        setValue('certificate', base64Content, { shouldDirty: true });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.crt,.pem,.cer'; // Укажите нужные форматы файлов
    fileInput.addEventListener('change', handleFileChange as unknown as EventListener);
    fileInput.click();
  };

  const setFields = async (
    provider?: Partial<
      TMiscProvider & {
        params: TEsiaParams;
        scopes: string;
        provider_id: string;
        avatar?: File | null;
      }
    >,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              params: TEsiaParams;
              scopes: string;
              provider_id: string;
              avatar?: File | null;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, type, params, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.scopes;

      if (typeof type === 'string' && type in MiscProviderType) {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<
                TMiscProvider,
                | 'avatar'
                | 'scopes'
                | 'type'
                | 'params'
                | 'id'
                | 'client_id'
                | 'auth_without_email'
                | 'password_required'
                | 'provider_colors'
                | 'provider_title'
                | 'show_provider_avatar'
                | 'disable_password_reset'
              >
            >
          ).forEach((field) => {
            if (field === 'is_active') return;
            if (field === 'auto_registration' || field === 'is_public') {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (params) {
          (Object.keys(params as TEsiaParams) as Array<keyof TEsiaParams>).forEach((field) => {
            if (field === 'scopes' && params?.[field]) {
              setChips(
                params?.[field]
                  ?.split(' ')
                  .map((scope, index) => ({ value: scope, key: String(index), changing: false })) ||
                  [],
              );
              return;
            }
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
      } else {
        setClipboardModalOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditEsiaProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              scopes: string;
              external_client_id: string;
              // authorization_endpoint: string;
              // token_endpoint: string;
              sign_endpoint: string;
              verify_endpoint: string;
              // userinfo_endpoint: string;
              issuer: string;
              certificate: string;
              // mapping: string;
              provider_id: string;
            }
          >,
          field,
        ) => {
          if (field === 'scopes') return acc;
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (field === 'auto_registration' || field === 'is_public') {
            acc[field] = data[field];
            return acc;
          }
          acc[field] = data[field];
          return acc;
        },
        {},
      );

      payload.scopes = chips.map((chip) => chip.value).join(' ');
      if (!isObjectEmpty(payload))
        updateProvider({
          body: {
            ...payload,
            provider_id: providerToEdit.id,
            type: providerToEdit.type,
          },
          client_id: clientId,
        });
    }
  };
  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Редактировать способ входа"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<EditEsiaProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={updateResult.isLoading}
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            providerToEdit={providerToEdit}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <LicenseSelect flag={MiscProviderType.ESIA} />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              required: true,
              onChange: () => {
                if (errors.external_client_id) clearErrors('external_client_id');
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Базовый адрес сервиса (issuer)
          </CustomTypography>
          <TextField
            {...register('issuer', {
              required: true,
              onChange: () => {
                if (errors.issuer) clearErrors('issuer');
              },
            })}
            className="custom"
            error={!!errors.issuer}
            helperText={errors.issuer ? errors.issuer.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Тестовый базовый адрес сервиса https://esia-portal1.test.gosuslugi.ru
          </CustomTypography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Сертификат подписи и проверки запросов
            </CustomTypography>
            <Button variant="custom2" className={styles['input-title']} onClick={handleButtonClick}>
              Загрузить
            </Button>
          </div>
          <TextField
            {...register('certificate', {
              required: true,
              onChange: () => {
                if (errors.certificate) clearErrors('certificate');
              },
            })}
            className="custom"
            error={!!errors.certificate}
            helperText={errors.certificate ? errors.certificate.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Сертификат, который с помощью которого подписываются и проверяются запросы
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес получения подписи запроса (sign_endpoint)
          </CustomTypography>
          <TextField
            {...register('sign_endpoint', {
              required: true,
              onChange: () => {
                if (errors.sign_endpoint) clearErrors('sign_endpoint');
              },
            })}
            className="custom"
            error={!!errors.sign_endpoint}
            helperText={errors.sign_endpoint ? errors.sign_endpoint.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Ресурс, который подписывает запрос
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес проверки подписи запроса (verify_endpoint)
          </CustomTypography>
          <TextField
            {...register('verify_endpoint', {
              required: true,
              onChange: () => {
                if (errors.verify_endpoint) clearErrors('verify_endpoint');
              },
            })}
            className="custom"
            error={!!errors.verify_endpoint}
            helperText={errors.verify_endpoint ? errors.verify_endpoint.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Ресурс, который проверяет подпись ответа от ЕСИА
          </CustomTypography>
          <ProviderFooter type={providerToEdit?.type as MiscProviderType} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
