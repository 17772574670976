import React from 'react';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from './EditUserProfile.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { TCustomFields } from '../../redux/userSlice';
import { checkIdentifier } from '../../requests/user';
import { editProfileSchema, getMonthsByYear, isDateError, isObjectEmpty } from '../../helpers';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { ProfileFields } from './ProfileFields';
import { Roles } from '../../enums';
import {
  useGetProfileFieldsQuery,
  useGetRulesQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { useUpdateUserByOwnerMutation } from '../../redux/services/owner';
import { useLazyCheckEmailAvialabilityQuery } from '../../redux/services/mail';
import { useLazyCheckPhoneExistsQuery } from '../../redux/services/phone';
import { userApi } from '../../redux/services/user';
import { CustomTypography } from '../custom/CustomTypography';
import { ConfirmationModal } from '../modal/ConfirmationModal';

type Inputs = {
  given_name: string;
  picture: File | string | null;
  nickname: string;
  family_name: string;
  login: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

const mapStateToProps = (state: RootState) => ({
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TEditProfileComponent = {
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const EditProfileAsOwnerComponent: FC<TEditProfileComponent> = ({
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const { data: rules, refetch: refetchRules } = useGetRulesQuery();
  useEffect(() => {
    refetchRules();
  }, []);

  const methods = useForm<Inputs & TCustomFields>({
    resolver: yupResolver(
      editProfileSchema(rules?.filter((rule) => rule.field_name !== 'password') || []),
    ),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;
  const [UpdateUserByOwner] = useUpdateUserByOwnerMutation();
  const [checkEmailAvialability, { isFetching: checkEmailFetching }] =
    useLazyCheckEmailAvialabilityQuery();
  const [checkPhoneExists, { isFetching: checkPhoneExistsFetching }] =
    useLazyCheckPhoneExistsQuery();

  // #427 const [isPublic, setIsPublic] = useState<boolean>(false);
  const navigate = useNavigate();
  const watchBirthDay = watch('birthDay');
  const watchBirthMonth = watch('birthMonth');
  const watchBirthYear = watch('birthYear');
  const [hasBirthdate, setHasBirthdate] = useState(false);
  const months = getMonthsByYear(watchBirthYear);
  const selectedMonth = months.find((month) => month.name === watchBirthMonth);
  const dispatch = useDispatch();
  const [getUsers] = useLazyGetUsersQuery();
  const { data: dataSettings } = useGetSettingsQuery();
  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: Roles } | null | undefined
  >(null);
  const { data: profileFields } = useGetProfileFieldsQuery();

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId]);

  useEffect(() => {
    setValue('birthDay', String(Math.min(+(selectedMonth?.days || ''), +watchBirthDay)));
  }, [watchBirthMonth, watchBirthYear]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (isChangesUnsaved !== isDirty) dispatch(setIsChangesUnsaved(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    const customFields = profileFields?.filter((key) => key.type === 'custom' && key.active) || [];

    (
      [
        'nickname',
        'given_name',
        'family_name',
        'login',
        'email',
        'phone_number',
        ...customFields.map((c) => c.field),
      ] as Array<keyof Omit<Inputs, 'birthDay' | 'birthMonth' | 'birthYear' | 'picture'>>
    ).forEach((field) => {
      if (customFields.find((c) => c.field === field)) {
        if (!selectedUser?.user.custom_fields || !selectedUser?.user.custom_fields[field]) {
          return;
        }
        setValue(
          field,
          selectedUser?.user.custom_fields ? selectedUser.user.custom_fields[field] : '',
        );
      }
      if (selectedUser?.user[field]) setValue(field, selectedUser?.user[field] || '');
    });
    if (selectedUser?.user.phone_number) {
      setValue('phone_number', selectedUser.user.phone_number.slice(1));
    }
    if (selectedUser?.user.birthdate) {
      const date = new Date(selectedUser.user.birthdate);
      setValue('birthYear', String(date.getFullYear()));
      setValue('birthMonth', months[date.getMonth()]?.name);
      setValue('birthDay', String(date.getDate()));
      setHasBirthdate(true);
    }
  }, [selectedUser?.user]);

  useEffect(() => {
    return () => {
      dispatch(setComponentPath('/profile'));
      setHasBirthdate(false);
    };
  }, []);

  const onSubmit: SubmitHandler<Inputs & TCustomFields> = async (data) => {
    try {
      let birthdate: null | Date = null;

      const customFields =
        profileFields?.filter((key) => key.type === 'custom' && key.active) || [];
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (acc: any, field) => {
          if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
            const month = months.findIndex(({ name }) => name === data.birthMonth);
            birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));
            return acc;
          }

          if (field === 'picture') {
            acc.picture = data.picture as string;
            return acc;
          }

          if (field === 'phone_number') {
            acc.phone_number = data.phone_number;
            return acc;
          }

          if (customFields.find((cf) => cf.field === field)) {
            acc.custom_fields = acc.custom_fields || {};
            acc.custom_fields[field] = data[field];
            return acc;
          }

          acc[field] = data[field];

          return acc;
        },
        {},
      );

      if ('login' in dirtyFields && selectedUser?.user.login !== data.login) {
        const isAvailable = await checkIdentifier(data.login);
        if (isAvailable) {
          setError('login', {
            type: 'api',
            message: 'Уже существует',
          });
          return;
        }
      }

      if ('phone_number' in dirtyFields && payload.phone_number && data.phone_number) {
        const response = await checkPhoneExists(data.phone_number).unwrap();

        if (!response.isAvailable) {
          setError('phone_number', {
            type: 'api',
            message: 'Номер телефона уже используется другим пользователем',
          });
          return;
        }
      }

      if ('email' in dirtyFields && data.email) {
        const response = await checkEmailAvialability(data.email).unwrap();

        if (!response.isAvailable) {
          setError('email', {
            type: 'api',
            message: 'Почтовый адрес уже используется',
          });
          return;
        }
      }

      if (!userId) return;
      if (dateError) return;
      if ('birthDay' in dirtyFields || 'birthYear' in dirtyFields || 'birthMonth' in dirtyFields)
        payload.birthdate = (birthdate as unknown as Date).toISOString();

      await UpdateUserByOwner({
        data: payload,
        user_id: userId,
      }).unwrap();
      navigate(`/applications/user/${clientId}/${userId}`);
      dispatch(userApi.util.invalidateTags(['ExternalAccounts']));
      dispatch(setIsChangesUnsaved(false));
    } catch (e) {
      console.log('updateUserError', e);
    }
  };

  const birthDate = new Date(
    +watchBirthYear,
    months.findIndex((month) => month.name === watchBirthMonth),
    +watchBirthDay,
  );
  const dateError = hasBirthdate && isDateError(birthDate, dataSettings);

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  return (
    <div className={'wrapper-scroll'}>
      <div className={'content'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
            <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
              Редактировать профиль
            </CustomTypography>
            <ProfileFields
              isLoading={checkEmailFetching || checkPhoneExistsFetching}
              editUserByOwner={true}
              userProfile={{ ...selectedUser?.user, role: selectedUser?.role }}
            />
          </form>
        </FormProvider>

        <ConfirmationModal
          title="Сохранение изменений"
          mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
          actionButtonText="Продолжить"
          isOpen={isModalOpen}
          onAction={() => {
            relocation
          }}
          onClose={() => dispatch(setIsModalOpen(false))}
        />
      </div>
    </div>
  );
};

export const EditProfileAsOwner = connect(mapStateToProps)(EditProfileAsOwnerComponent);
