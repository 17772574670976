import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TKloudParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { isObjectEmpty } from '../../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom-v5-compat';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { LicenseSelect } from '../applications/LicenseSelect';
import { SharedFormProvider } from '../shared/FormProvider';
import { ConfirmationModal } from '../modal/ConfirmationModal';

export type EditKloudProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
  external_client_id: string;
  external_client_secret: string;
  issuer?: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .min(1, 'Обязательное поле')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .nullable(true)
    .transform((v) => (typeof v === 'undefined' ? null : v)),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

const kloudParams = ['issuer', 'external_client_id', 'external_client_secret'];

export const EditKloudProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditKloudProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<string | null>(null);
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (provider?: Partial<TMiscProvider & { provider_id: string }>) => {
    try {
      let selectedCopy:
        | Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { type, avatar, params, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;

      if (type !== MiscProviderType.KLOUD) {
        setClipboardModalOpen(true);
      } else {
        if (avatar && typeof avatar !== 'string') {
          setOverrideImage(URL.createObjectURL(avatar));
          setValue('avatar', avatar, { shouldDirty: !provider });
        }

        if (params) {
          (Object.keys(params) as Array<keyof TKloudParams>).forEach((field) => {
            setValue(field, (params as TKloudParams)?.[field] || '', { shouldDirty: !provider });
          });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<EditKloudProviderInputs, keyof TKloudParams | 'avatar'>
            >
          ).forEach((field) => {
            if (
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditKloudProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'params'> & {
              avatar?: File | null;
              provider_id: string;
            } & TKloudParams
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'password_required' ||
            field === 'is_public'
          ) {
            acc[field] = data[field];
            return acc;
          }
          if (kloudParams.includes(field)) {
            (kloudParams as Array<keyof TKloudParams>).forEach((field) => {
              acc[field] = data[field];
            });
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType.KLOUD,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Редактировать способ входа KLOUD"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<EditKloudProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={updateResult.isLoading}
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            providerToEdit={providerToEdit}
            setAvatarValue={setAvatarValue}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <LicenseSelect flag={MiscProviderType.KLOUD} />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              required: true,
              onChange: () => {
                if (errors.external_client_id) clearErrors('external_client_id');
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Секретный ключ (client_secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('external_client_secret', {
              required: true,
              onChange: () => {
                if (errors.external_client_secret) clearErrors('external_client_secret');
              },
            })}
            className="custom"
            error={!!errors.external_client_secret}
            helperText={
              errors.external_client_secret ? errors.external_client_secret.message : ''
            }
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Секретный ключ ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Базовый адрес сервера авторизации (issuer)
          </CustomTypography>
          <TextField
            {...register('issuer', {
              required: true,
              onChange: () => {
                if (errors.issuer) clearErrors('issuer');
              },
            })}
            style={{ marginBottom: 32 }}
            className="custom"
            error={!!errors.issuer}
            helperText={errors.issuer ? errors.issuer.message : ''}
            fullWidth
            variant="standard"
          />
          <ProviderFooter type={MiscProviderType.KLOUD} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для KLOUD провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
