import React, { FC } from 'react';
import { useGetApplicationByIdQuery } from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import { EditSettings } from './EditSettings';
import { CLIENT_ID } from '../../constants';
import { useParams } from 'react-router-dom-v5-compat';
import { EditApplication } from './EditApplication';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

const SettingsWrapperComponent: FC<{ userId?: string }> = ({ userId }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const { data: selectedClient } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId || '' },
    {
      skip: !userId || !clientId,
    },
  );

  return (
    <>
      {selectedClient && userId && clientId === selectedClient.client.client_id ? (
        clientId === CLIENT_ID ? (
          <EditSettings selectedClient={selectedClient.client} userId={userId} />
        ) : (
          <EditApplication selectedClient={selectedClient.client} userId={userId} />
        )
      ) : (
        <div>Загрузка...</div>
      )}
    </>
  );
};

export const SettingsWrapper = connect(mapStateToProps)(SettingsWrapperComponent);
