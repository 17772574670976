import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { Roles } from 'src/enums';
import {
  createBaseQuery,
  createFetchArgs,
  createFetchArgsWithBody,
  parseResponse,
} from '../helpers';
import {
  IQueryIdProps,
  IQueryPropsWithClientId,
  IQuerySortParams,
  responseListItems,
} from '../types';
import { TUserWithRole } from './users';

export type TShortClient = {
  client_id: string;
  name: string;
  description: string | null;
  domain: string;
  avatar: string | null;
  created_at: string;
};

export interface queryIdPropsWithRole extends IQueryIdProps {
  role: Roles;
}

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Clients'],
  baseQuery: createBaseQuery('clients'),
  endpoints: (builder) => ({
    deleteSession: builder.mutation({
      query: ({ clientId, userId }: IQueryIdProps) =>
        createFetchArgs(`${clientId}/users/${userId}/sessions`, 'DELETE'),
    }),
    getUsersClient: builder.query<responseListItems<TUserWithRole[]>, IQueryPropsWithClientId>({
      query: (params: IQueryPropsWithClientId) =>
        createFetchArgs<IQuerySortParams>(`${params.clientId}/users`, 'GET', params.query),
      transformResponse: (users: TUserWithRole[], meta: FetchBaseQueryMeta) =>
        parseResponse<TUserWithRole[]>(users, meta),
    }),
    updateUserRoleClient: builder.mutation({
      query: (params: queryIdPropsWithRole) =>
        createFetchArgsWithBody<{ role: Roles }>(
          `${params.clientId}/users/${params.userId}/role`,
          'PUT',
          { role: params.role },
        ),
    }),
  }),
});

export const {
  useDeleteSessionMutation,
  useGetUsersClientQuery,
  useLazyGetUsersClientQuery,
  useUpdateUserRoleClientMutation,
} = clientsApi;
