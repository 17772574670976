import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import React from 'react';
import { formatDate } from 'src/helpers';
import { TLogger } from 'src/redux/services/logger';
import { CustomTypography } from '../custom/CustomTypography';
import { Card, ICardProps } from '../shared/Card';
import styles from './EventLogCard.module.css';
import { LOADING_TEXT } from 'src/constants';

export interface ICardEventLogProps extends ICardProps {
  items: TLogger[];
  index: number;
  openModal: (value: TLogger) => void;
  isUserSpecific?: boolean;
}

const CardEventLogComponent: React.FC<ICardEventLogProps> = (props) => {
  const { items, index, isUserSpecific, openModal } = props;
  const event = items[index] || {};

  function translateLevel(level: string) {
    switch (level) {
      case 'INFO':
        return 'Информация';
      case 'WARNING':
        return 'Предупреждение';
      case 'DEBUG':
        return 'Отладка';
      default:
        return 'Ошибка';
    }
  }

  return (
    <Card
      {...props}
      cardId={event?.id?.toString()}
      onClick={() => openModal(event)}
      content={
        <div className={styles.info}>
          <div className={styles.chipContainer}>
            <Chip label={translateLevel(event?.level)} className={clsx("text-12", styles.chip)} />
          </div>
          <CustomTypography className={clsx('text-14', styles.infoTitle)}>
            {event?.event || LOADING_TEXT}
          </CustomTypography>
          <div className={styles.infoRow}>
            <CustomTypography
              className={clsx('text-12', styles.infoText)}
            >
              Дата и время:
            </CustomTypography>
            <CustomTypography className="text-12">
              {formatDate(event?.date) || LOADING_TEXT}
            </CustomTypography>
          </div>
          {!isUserSpecific && <div className={styles.infoRow}>
            <CustomTypography className={clsx('text-12', styles.infoText)}>
              Пользователь:
            </CustomTypography>
            <CustomTypography className="text-12">{event?.user_id || '-'}</CustomTypography>
          </div>}
        </div>
      }
    />
  );
};

export const CardEventLog = (CardEventLogComponent);
