import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { TUserSlice } from '../../redux/userSlice';
import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './UserProfile.module.css';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import {
  exportToJson,
  formatDate,
  formatPhoneNumber,
  getClaimPrivacy,
  getImageURL,
} from '../../helpers';
import {
  AccountTypes,
  useGetExternalAccountsQuery,
  useGetPrivateClaimsQuery,
} from '../../redux/services/user';
import { DATA_PROCESSING_POLICY_URL } from '../../constants';
import { PublicStatusPopover } from './PublicStatusPopover';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { ExternalAccount } from './ExternalAccount';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { ChangePasswordBlock } from './ChangePasswordBlock';
import { PublicProfileBlock } from './PublicProfileBlock';
import { PublicProfile } from './PublicProfile';
import { useGetProfileFieldsQuery } from '../../redux/services/settings';
import { Roles } from '../../enums';
import { setNotice } from '../../redux/noticesSlice';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomIcon } from '../custom/CustomIcon';
import { ConfirmationModal } from '../modal/ConfirmationModal';

const mapStateToProps = ({ user }: RootState) => ({
  userProfile: user.userProfile,
});

type TUserProfileComponent = {
  userProfile: TUserSlice['userProfile'];
};

const UserProfileComponent: FC<TUserProfileComponent> = ({ userProfile }) => {
  const dispatch = useDispatch();
  const date = userProfile.birthdate ? new Date(userProfile.birthdate) : null;
  const passwordUpdateDate = new Date(userProfile.password_updated_at || '');
  const navigate = useNavigate();
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const {
    public_profile_claims_oauth,
    public_profile_claims_gravatar,
    public_accounts_claims_oauth,
    public_accounts_claims_gravatar,
  } = privateClaims || {};
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();

  const [isOutModalOpen, setOutModalOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    if (userProfile.email) {
      setDrawerOpen(true);
    } else {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: 'Публичные данные доступны при наличии электронной почты',
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const cusomFields =
    profileFields?.filter((field) => field.type === 'custom' && field.active) || [];
  const lineCustomFields = cusomFields.map((field) => {
    return (
      <div key={field.field} className={styles['info-item']}>
        <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
          {field.title}
        </CustomTypography>
        <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
          {userProfile.custom_fields && userProfile.custom_fields[field.field]
            ? userProfile.custom_fields[field.field]
            : 'Не задано'}
        </CustomTypography>
        <PublicStatusPopover
          claimPrivacy={getClaimPrivacy(
            field.field,
            public_profile_claims_oauth,
            public_profile_claims_gravatar,
          )}
          claims={field.field}
          userId={userProfile.id}
        />
      </div>
    );
  });

  return (
    <div className={'wrapper-scroll'}>
      <div className={'content'}>
        <CustomTypography style={{ marginBottom: 14 }} className={clsx('text-14')} color="grey">
          Вы можете управлять доступом к вашему аккаунту. Настройте какие данные будут предоставлены
          приложениям после вашего согласия.
        </CustomTypography>
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <CustomTypography className={clsx('header-3', 'font-golos')}>
              Основная информация
            </CustomTypography>
            {userProfile.role !== Roles.TRUSTED_USER && (
              <Link
                to="/profile/edit"
                className={styles['margin-right']}
                style={{ marginLeft: 'auto', textDecoration: 'none' }}
              >
                <Button variant="custom2">Изменить</Button>
              </Link>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  ID пользователя
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {userProfile.id}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'id',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="id"
                disabled
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Публичное имя
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {userProfile.nickname ? userProfile.nickname : 'Нет имени'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'nickname',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="nickname"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
                Фото профиля
              </CustomTypography>
              {userProfile.picture ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(userProfile?.picture)})`,
                  }}
                  className={styles['user-icon-wrapper']}
                />
              ) : (
                <Avatar className={styles.avatar}>
                  <AvatarIcon />
                </Avatar>
              )}
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'picture',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="picture"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Имя и фамилия
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {(
                    (userProfile.given_name || '') +
                    ' ' +
                    (userProfile.family_name || '')
                  ).trim() || 'Не задано'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'family_name',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="family_name given_name"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Логин
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {userProfile.login || 'Не задано'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'login',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="login"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Дата рождения
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {date ? formatDate(date) : 'Не задано'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'birthdate',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="birthdate"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Электронная почта
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {userProfile.email ? userProfile.email : 'Не задано'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'email',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="email"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Номер телефона
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {userProfile.phone_number
                    ? formatPhoneNumber(userProfile.phone_number)
                    : 'Не задано'}
                </CustomTypography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'phone_number',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="phone_number"
                userId={userProfile.id}
              />
            </div>
            {lineCustomFields.length > 0 && (
              <div className={styles['panel-title']}>
                <CustomTypography className={clsx('header-3', 'font-golos')}>
                  Дополнительная информация
                </CustomTypography>
              </div>
            )}
            {lineCustomFields}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <CustomTypography className={clsx('text-17-regular', 'font-golos')}>
              Идентификаторы
            </CustomTypography>
            {userProfile.role !== Roles.TRUSTED_USER && (
              <Button
                variant="custom2"
                className={styles['add-button']}
                onClick={() => navigate('/profile/external-provider')}
              >
                Добавить
              </Button>
            )}
          </div>
          <div className={styles.info}>
            {externalAccounts
              ?.filter((ea) => ea.type !== AccountTypes.EMAIL && ea.type !== AccountTypes.PHONE)
              .map((account) => (
                <ExternalAccount
                  account={account}
                  userProfile={userProfile}
                  public_accounts_claims_oauth={public_accounts_claims_oauth}
                  public_accounts_claims_gravatar={public_accounts_claims_gravatar}
                  key={
                    (account.sub || '') +
                    (account.issuer || '') +
                    (account.type || '') +
                    (account.email || '')
                  }
                  withoutButtons={
                    account.type === AccountTypes.ALDPRO ||
                    account.type === AccountTypes.IDM ||
                    account.type === AccountTypes.LDAP ||
                    account.type === AccountTypes._1C
                      ? true
                      : false
                  }
                />
              ))}
          </div>
        </div>
        <ChangePasswordBlock
          passwordUpdateDate={passwordUpdateDate}
          navigateTo="/profile/change-password"
        />
        <PublicProfileBlock onOpenDrawer={handleOpenDrawer} />
        <PublicProfile
          userEmail={userProfile.email || ''}
          isOpen={isDrawerOpen}
          close={handleCloseDrawer}
        />
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={
              <IconButton size="large" disableRipple={false}>
                <CustomIcon Icon={ArrowDownIcon} />
              </IconButton>
            }
          >
            <CustomTypography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </CustomTypography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={() => setOutModalOpen(true)}
            >
              Выйти со всех устройств
            </Button>
            <a href={DATA_PROCESSING_POLICY_URL}>
              <Button variant="custom2" className={styles['margin-bottom']}>
                Политика обработки ПДн
              </Button>
            </a>
            <Button
              onClick={() => exportToJson(userProfile, 'profile.json')}
              variant="custom2"
              className={styles['margin-bottom']}
            >
              Скачать данные
            </Button>
            {userProfile.id && parseInt(userProfile.id, 10) !== 1 && (
              <Button
                onClick={() => navigate('/profile/delete')}
                variant="custom2"
                className={styles['margin-bottom']}
              >
                Удалить аккаунт
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <ConfirmationModal
        title="Выход со всех устройств"
        mainMessage={["Выход из аккаунта произойдет на всех устройствах. Вам необходимо будет пройти авторизацию заново. Продолжить?"]}
        actionButtonText="Продолжить"
        isOpen={isOutModalOpen}
        onAction={async () => {
          setOutModalOpen(false);
          await deleteAllSession(userProfile.id);
          window.location.reload();
        }}
        onClose={() => setOutModalOpen(false)}
      />
    </div>
  );
};

export const UserProfile = connect(mapStateToProps)(UserProfileComponent);
