import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  MiscProviderType,
  T1CParams,
  TEditProvider,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../../redux/services/provider';

import { isObjectEmpty } from '../../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import Switch from '@mui/material/Switch';
import { ProviderColor, TProviderColors } from './ProviderColor';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { LicenseSelect } from '../applications/LicenseSelect';
import { SharedFormProvider } from '../shared/FormProvider';
import { ConfirmationModal } from '../modal/ConfirmationModal';

const _1CParams = ['url', 'admin_login', 'admin_password', 'mapping', 'secret'];

export type Edit1CProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  admin_login: string;
  admin_password: string;
  secret: string;
  provider_colors: TProviderColors;
  auto_registration: boolean;
  mapping: string;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  provider_title: string;
  show_provider_avatar: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера 1C не может превышать 255 символов')
    .url('Введите корректный адрес'),
  admin_login: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Логин не может превышать 255 символов'),
  admin_password: yup.string().max(255, 'Пароль не может превышать 255 символов'),
  secret: yup.string().required('Обязательное поле'),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  disable_password_reset: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  provider_colors: yup.object({
    button_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
    font_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
  }),
});

export const Edit1CProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<Edit1CProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, update1CResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const showAvatar = useWatch({ control, name: 'show_provider_avatar' });

  useEffect(() => {
    if (update1CResult.isSuccess) close();
  }, [update1CResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (provider?: Partial<TMiscProvider & { provider_id: string }>) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              provider_id: string;
              client_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, params, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.client_id;

      if (type !== MiscProviderType._1C) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (params) {
          (Object.keys(params) as Array<keyof T1CParams>).forEach((field) => {
            setValue(field, (params as T1CParams)?.[field] || '', { shouldDirty: !provider });
          });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<Edit1CProviderInputs, keyof T1CParams | 'avatar'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public' ||
              field === 'disable_password_reset'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }

            if (field === 'provider_colors') {
              return setValue(
                field,
                { ...(restInputs?.[field] as TProviderColors) },
                { shouldDirty: !provider },
              );
            }

            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<Edit1CProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'params'> & {
              avatar?: File | null;
              provider_id: string;
            } & T1CParams
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public' ||
            field === 'show_provider_avatar' ||
            field === 'disable_password_reset'
          ) {
            acc[field] = data[field];
            return acc;
          }

          if (field === 'provider_colors') {
            acc[field] = data[field];
            return acc;
          }

          if (_1CParams.includes(field)) {
            (_1CParams as Array<keyof T1CParams>).forEach((param) => {
              acc[param] = data[param];
            });
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType._1C,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Редактировать провайдер 1C"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<Edit1CProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={update1CResult.isLoading}
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            providerToEdit={providerToEdit}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <LicenseSelect flag={MiscProviderType._1C} />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес сервера 1C
          </CustomTypography>
          <TextField
            {...register('url', {
              required: true,
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('url', event.target.value.trim());
              },
              onChange: () => {
                if (errors.url) clearErrors('url');
              },
            })}
            className="custom"
            error={!!errors.url}
            helperText={errors.url ? errors.url.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес публикации информационной базы
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Логин 1C
          </CustomTypography>
          <TextField
            {...register('admin_login', {
              required: true,
              onChange: () => {
                if (errors.admin_login) clearErrors('admin_login');
              },
            })}
            className="custom"
            error={!!errors.admin_login}
            helperText={errors.admin_login ? errors.admin_login.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Логин для доступа к 1C
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Пароль 1C
          </CustomTypography>
          <PasswordTextfield
            {...register('admin_password', {
              onChange: () => {
                if (errors.admin_password) clearErrors('admin_password');
              },
            })}
            className="custom"
            error={!!errors.admin_password}
            helperText={errors.admin_password ? errors.admin_password.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Пароль для доступа к 1C
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Секретный ключ (Secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('secret', {
              required: true,
              onChange: () => {
                if (errors.secret) clearErrors('secret');
              },
            })}
            className="custom"
            error={!!errors.secret}
            helperText={errors.secret ? errors.secret.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Секретный ключ ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Сопоставление атрибутов 1C
          </CustomTypography>
          <TextField
            {...register('mapping', {
              required: true,
              onChange: () => {
                if (errors.mapping) clearErrors('mapping');
              },
            })}
            className="custom"
            error={!!errors.mapping}
            helperText={errors.mapping ? errors.mapping.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Сопоставление атрибутов профиля пользователя Trusted.ID с атрибутами пользователя
            внешней системы в формате: given_name:firstName, family_name:lastName, email:email,
            picture:photo
          </CustomTypography>
          <ProviderFooter type={MiscProviderType._1C} clientId={clientId} />
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Показывать логотип 1C в виджете
            </CustomTypography>
            <Switch
              checked={showAvatar}
              {...register('show_provider_avatar', {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  setValue('show_provider_avatar', event.target.checked, { shouldDirty: true });
                },
              })}
            />
          </div>
          <ProviderColor avatarSrc={avatarSrc} providerName={providerToEdit?.name} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для 1C-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
