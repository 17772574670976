import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const authApi = createApi({
  reducerPath: 'AuthApi',
  tagTypes: ['Api'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/auth/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),

  endpoints: (builder) => ({
    deleteAllSession: builder.query({
      query: (userId) => ({
        method: 'DELETE',
        url: `${BACKEND_URL}/api/v1/users/${userId}/sessions`,
        credentials: 'include',
      }),
    }),

    getOauth: builder.mutation<
      { url: string },
      {
        provider_id: string;
        state: string;
        return_url?: boolean;
      }
    >({
      query: (query) => ({
        method: 'GET',
        url: '/oauth',
        params: query,
      }),
    }),
  }),
});

export const { useLazyDeleteAllSessionQuery, useGetOauthMutation } = authApi;
