import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOADING_TEXT, PROJECT_NAME } from 'src/constants';
import { Roles } from 'src/enums';
import { formatDate, getImageURL, getRoleName } from 'src/helpers';
import { setNotice, TNotice } from 'src/redux/noticesSlice';
import {
  useDeleteSessionMutation,
  useUpdateUserRoleClientMutation,
} from 'src/redux/services/clients';
import {
  TUser,
  TUserWithRole,
  useBlockUserMutation,
  useDeleteUserMutation,
  useUnblockUserMutation,
} from 'src/redux/services/users';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { ThemeColors } from '../custom/Theme';
import { CustomRadioButton } from '../CustomRadioButton';
import { IConfirmationModalProps } from '../modal/ConfirmationModal';
import { ModalInfo } from '../modal/ModalInfo';
import { ActionButtons } from '../shared/ActionButtons';
import { Card, ICardProps } from '../shared/Card';
import { MenuControls } from '../shared/MenuControls';
import styles from './UserCard.module.css';
import { getDisplayName } from './utils';

export interface IUserCardProps extends ICardProps {
  items: TUserWithRole[];
  index: number;
  clientId: string;
  onClick: (userId?: number) => void;
  setConfirmModalProps: Dispatch<SetStateAction<IConfirmationModalProps>>;
  updateItems: (items: TUserWithRole[], totalCount: number) => void;
}

const UserCardComponent: React.FC<IUserCardProps> = (props) => {
  //Состояния
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRoleModalOpen, setIsRoleOpenModal] = useState(false);
  const [userRole, setUserRole] = useState<Roles | null>(null);

  const dispatch = useDispatch();
  const { items, index, clientId, onClick, setConfirmModalProps, updateItems } = props;
  const { user, role } = items[index] || {};

  //Запросы
  const [deleteSession] = useDeleteSessionMutation();
  const [updateUserRoleClient] = useUpdateUserRoleClientMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [blockUser] = useBlockUserMutation();
  const [unblockUser] = useUnblockUserMutation();

  //#region Шаблоны для отображения текста
  const textWrapper = (text: string) => {
    return user ? text || '' : LOADING_TEXT;
  };

  const getUserStatus = (user: TUser) => {
    let stateStatus: { text: string; color?: ThemeColors } = {
      text: 'Активный',
      color: undefined,
    };
    if (user?.blocked) {
      stateStatus = { text: 'Заблокированный', color: 'error' };
    } else if (user?.deleted) {
      const deleteDate = new Date(user?.deleted);
      stateStatus = { text: `Будет удален ${formatDate(deleteDate)}`, color: 'grey' };
    }

    return (
      <CustomTypography className={clsx('text-14', styles.infoTitle)} color={stateStatus.color}>
        {stateStatus.text}
      </CustomTypography>
    );
  };

  const getRadioButton = (item: { title: string; description: string; role: Roles }, index: number) => {
    return (
      <div key={index}>
        <CustomRadioButton
          label={item.title}
          checked={userRole === item.role}
          onClick={() => {
            setUserRole(item.role);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 16 }}
          className={'text-12'}
          color="grey"
        >
          {item.description}
        </CustomTypography>
      </div>
    );
  };
  //#endregion

  //#region Выбор прав пользователя
  const rolesForChoose: { title: string; description: string; role: Roles }[] = [
    {
      title: 'Участник',
      description: 'Имеет доступ в приложение и может просматривать профиль приложения',
      role: Roles.USER,
    },
    {
      title: 'Администратор приложений',
      description:
        'Может создавать свои приложения, управлять полномочиями участников своих приложений, просматривать персональные данные участников своих приложений',
      role: Roles.ADMIN,
    },
    {
      title: 'Администратор',
      description: 'Имеет полный контроль над всеми пользователями и приложениями',
      role: Roles.EDITOR,
    },
  ];

  const handleSaveRole = async () => {
    const notice = (isSuccess: boolean): TNotice => {
      return {
        id: Math.random(),
        isRead: false,
        message: isSuccess
          ? `Права пользователя ${getDisplayName(user)} в ${PROJECT_NAME} изменены.`
          : `Не удалось изменить права пользователя ${getDisplayName(user)} в ${PROJECT_NAME}.`,
        timestamp: new Date().toString(),
        avatar: user?.picture,
      };
    };

    try {
      if (userRole) {
        await updateUserRoleClient({
          clientId,
          userId: user?.id.toString(),
          role: userRole,
        }).unwrap();
        items[index] = { user, role: userRole };
        updateItems(items, items.length);
        dispatch(setNotice(notice(true)));
      } else {
        throw Error;
      }
    } catch (error) {
      const not = notice(false);
      dispatch(setNotice(not));
    }

    setIsRoleOpenModal(false);
  };
  //#endregion

  //#region Модальное окно подтверждения действия
  const onCloseConfirmModal = () => {
    setConfirmModalProps((prev) => {
      return { ...prev, isOpen: false };
    });
  }

  const handleDeleteSessions = async () => {
    try {
      await deleteSession({ userId: user.id.toString(), clientId }).unwrap();
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const handleBlocked = async () => {
    try {
      let isBlocked = false;
      if (user.blocked) {
        await unblockUser({ id: user.id.toString() }).unwrap();
      } else {
        await blockUser({ id: user.id.toString() }).unwrap();
        isBlocked = true;
      }
      items[index] = { user: { ...user, blocked: isBlocked }, role };
      updateItems(items, items.length);
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const handleRemove = async () => {
    try {
      await deleteUser({ id: user.id.toString() }).unwrap();
      items.splice(index, 1);
      updateItems(items, items.length);
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const confirmModalPrams = [
    {
      onAction: () => {
        handleDeleteSessions();
      },
      actionButtonText: 'Завершить',
      mainMessage: [
        `Все сессии и токены для пользователя ${getDisplayName(user)} будут удалены`,
      ],
    },
    {
      onAction: () => {
        handleBlocked();
      },
      actionButtonText: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'}`,
      mainMessage: [
        `Пользователь ${getDisplayName(user)} ${
          user?.blocked ? 'получит' : 'потеряет'
        } доступ к приложениям, в которых для входа использовал аккаунт ${PROJECT_NAME} 
        Данные, связанные с аккаунтом, будут сохранены.`,
      ],
    },
    {
      onAction: () => {
        handleRemove();
      },
      actionButtonText: 'Удалить',
      //TODO реализовать выбор вывода имени пользователя
      mainMessage: [
        `Аккаунт ${getDisplayName(user)} будет удален. 
        Данные, связанные с аккаунтом, удалятся навсегда. 
        Пользователь потеряет доступ к приложениям, в которых для входа использовал аккаунт ${PROJECT_NAME}`,
      ],
    },
  ];
  //#endregion

  //#region Меню выбора действия над пользователем
  const handleOpenMenuControl = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuControl = () => {
    setAnchorEl(null);
  };

  
  const userControls = [
    {
      icon: ManageAccountsOutlinedIcon,
      title: 'Изменить права',
      action: () => {
        handleCloseMenuControl();
        setUserRole(role);
        setIsRoleOpenModal(true);
      },
      addDivider: true,
    },
    {
      icon: LogoutOutlinedIcon,
      title: 'Завершить сеансы',
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: 'Завершить сеансы',
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[0],
        });
      },
    },
    {
      icon: LockOutlinedIcon,
      title: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'} в ${PROJECT_NAME}`,
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'} в ${PROJECT_NAME}`,
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[1],
        });
      },
    },
    {
      icon: DeleteOutlineOutlinedIcon,
      title: 'Удалить аккаунт',
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: 'Удалить аккаунт',
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[2],
        });
      },
    },
  ];
  //#endregionw

  return (
    <>
      <Card
        {...props}
        cardId={user?.id?.toString()}
        isImage
        avatarUrl={user?.picture}
        onClick={() => onClick(user.id)}
        content={
          <div className={styles.content}>
            <div className={styles.userInfo}>
              <Box className={styles.userMainInfo}>
                <CustomTypography className={clsx('text-14', styles.userTitle)}>
                  {textWrapper(user?.given_name)} {textWrapper(user?.family_name)}
                </CustomTypography>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {textWrapper(user?.nickname)}
                </CustomTypography>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {textWrapper(`Id ${user?.id}`)}
                </CustomTypography>
              </Box>
              <Box className={styles.userRole}>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  Права:
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles.infoTitle)}>
                  {textWrapper(getRoleName(role))}
                </CustomTypography>
              </Box>
              <Box className={styles.userStatus}>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  Статус:
                </CustomTypography>
                {getUserStatus(user)}
              </Box>
            </div>
            <div className={styles.moreButtonWrapper}>
              <Button data-id={'button-menu-controls'} onClick={handleOpenMenuControl} className={styles.moreButton}>
                <CustomMuiIcon Icon={MoreHorizOutlinedIcon} customColor={'grey'} />
              </Button>
              <MenuControls
                anchorEl={anchorEl}
                handleClosePopover={handleCloseMenuControl}
                controls={userControls}
              />
            </div>
          </div>
        }
      />
      <ModalInfo
        isOpen={isRoleModalOpen}
        title={'Изменить права'}
        onClose={() => setIsRoleOpenModal(false)}
      >
        <>
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')}>
            Выберите права в {PROJECT_NAME} для пользователя:
          </CustomTypography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
            <Avatar src={getImageURL(user?.picture)} className={styles.avatar}>
              {!user?.picture && (
                <CustomTypography className={clsx('text-14')}>
                  {getDisplayName(user, true)
                    ?.split(' ')
                    .map((name: string) => name[0]?.toUpperCase())
                    .join('')}
                </CustomTypography>
              )}
            </Avatar>
            <CustomTypography className={clsx('text-14')}>{getDisplayName(user)}</CustomTypography>
          </div>
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-15-medium')}>
            Уровень полномочий
          </CustomTypography>
          {rolesForChoose.map((item, index) => getRadioButton(item, index))}
          <ActionButtons
            handleCancel={() => setIsRoleOpenModal(false)}
            confirmTitle={'Сохранить'}
            handleConfirm={handleSaveRole}
          />
        </>
      </ModalInfo>
    </>
  );
};

export const UserCard = UserCardComponent;
