import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom-v5-compat';
import { cardHeight, Order, screenDevices } from 'src/enums';
import { TScope, useLazyGetUserScopesQuery } from 'src/redux/services/users';
import { IQueryPropsWithUserId } from 'src/redux/types';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import { ConfirmationModal, IConfirmationModalProps } from '../modal/ConfirmationModal';
import { ListItems } from '../shared/listElements';
import CardScope, { ICardScopeProps } from './ScopeCard';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
});

interface IScopesListProps {
  screenSize: number;
}

const ScopesListComponent: FC<IScopesListProps> = ({ screenSize }) => {
  const [modalProps, setModalProps] = useState<IConfirmationModalProps>({
    isOpen: false,
    onAction: () => console.log(''),
    onClose: () => console.log(''),
    title: '',
    actionButtonText: '',
    mainMessage: [],
  });
  const location = useLocation();
  const { userId }: { userId: string } = location.state || {};
  const [getScopes] = useLazyGetUserScopesQuery();
  const query = (offset: number, search?: string): IQueryPropsWithUserId => {
    return {
      query: {
        sortBy: 'created_at',
        sortDirection: Order.DESC,
        limit: '10',
        offset,
        search: search || '',
      },
      userId: String(userId),
    };
  };

  const getCardHeight = (screenSize: number) => {
    switch (screenSize) {
      case screenDevices.Desktop:
        return cardHeight.Desktop;
      case screenDevices.Tablet:
        return 140;
      default:
        return 170;
    }
  };
  const height = getCardHeight(screenSize);

  return (
    <div data-id="scopes">
      {userId ? (
        <>
          <ListItems<TScope, IQueryPropsWithUserId, ICardScopeProps>
            heightListItem={height}
            padding={cardHeight.Indent}
            query={query}
            getItems={getScopes}
            RowElement={CardScope}
            rowElementProps={{
              userId,
              height,
              padding: cardHeight.Indent,
              setModalProps: setModalProps,
            }}
          />
          <ConfirmationModal
            isOpen={modalProps.isOpen}
            onAction={modalProps.onAction}
            onClose={modalProps.onClose}
            title={modalProps.title}
            actionButtonText={modalProps.actionButtonText}
            mainMessage={modalProps.mainMessage}
          />
        </>
      ) : (
        <CustomTypography>Загрузка...</CustomTypography>
      )}
    </div>
  );
};

export const ScopesList = connect(mapStateToProps)(ScopesListComponent);
