import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { ActionButtons } from './ActionButtons';
import styles from './FormProvider.module.css';

interface IFormProviderProps<T extends FieldValues> {
  methods: UseFormReturn<T, object>;
  onSubmit: () => void;
  handleCancel: () => void;
  isDisabled: boolean;
  children: ReactNode;
  acceptTitle?: string;
}

const FormProviderComponent = <T extends FieldValues>({
  methods,
  onSubmit,
  handleCancel,
  isDisabled,
  children,
  acceptTitle,
}: IFormProviderProps<T>) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className={styles.wrapper}>
        {children}
        <div className={styles.divider} />
        <ActionButtons
          handleCancel={handleCancel}
          confirmTitle={acceptTitle || 'Сохранить'}
          handleConfirm={onSubmit}
          disabled={isDisabled}
        />
      </form>
    </FormProvider>
  );
};

/**
 * Компонент FormProvider управляет логикой и состоянием формы элементов Provider.
 *
 * @component
 * @param handleCancel - Функция, вызываемая при нажатии на кнопку "Отмена". Используется для закрытия формы или сброса изменений.
 * @param acceptTitle - Текст, отображаемый на кнопке "Подтвердить". По умолчанию: 'Сохранить'.
 * @param onSubmit - Функция, вызываемая при нажатии на кнопку "Подтвердить". Используется для обработки отправки формы.
 * @param isDisabled - Флаг, определяющий, должна ли кнопка "Подтвердить" быть отключена. Используется для предотвращения отправки формы при некорректных данных.
 * @param methods - Объект методов управления формой из React Hook Form.
 */
export const SharedFormProvider = FormProviderComponent;
