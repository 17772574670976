import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CLIENT_ID } from 'src/constants';
import { cardHeight, screenDevices } from 'src/enums';
import { RootState } from 'src/redux/rootReducer';
import { useLazyGetUsersClientQuery } from 'src/redux/services/clients';
import { TUserWithRole } from 'src/redux/services/users';
import { IQueryPropsWithClientId } from 'src/redux/types';
import { CustomTypography } from '../custom/CustomTypography';
import { ConfirmationModal, IConfirmationModalProps } from '../modal/ConfirmationModal';
import { ListItems } from '../shared/listElements';
import { IUserCardProps, UserCard } from './UserCard';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
});

interface IUsersListProps {
  screenSize: number;
}

const UsersListComponent: FC<IUsersListProps> = ({ screenSize }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState<IConfirmationModalProps>({
    isOpen: false,
    onAction: () => console.log(''),
    onClose: () => console.log(''),
    title: '',
    actionButtonText: '',
    mainMessage: [],
  });
  const { clientId }: { clientId: string } = location.state || {};
  const [getUsers] = useLazyGetUsersClientQuery();
  const query = (offset: number, search?: string): IQueryPropsWithClientId => {
    return {
      query: {
        limit: '10',
        offset,
        search: search || '',
      },
      clientId: String(clientId),
    };
  };

  const getCardHeight = useCallback(
    (screenSize: number) => {
      switch (screenSize) {
        case screenDevices.Desktop:
          return cardHeight.Desktop;
        case screenDevices.Tablet:
          return 160;
        case screenDevices.miniTablet:
          return 190;
        case screenDevices.Mobile:
          return 210;
        default:
          return 170;
      }
    },
    [screenDevices, cardHeight],
  );
  const height = getCardHeight(screenSize);

  const createUserButton = useMemo(
    () => (
      <Tooltip title="Создать пользователя">
        <Button
          data-id={'button-create-user'}
          className="text-15-medium"
          variant="custom"
          component="label"
          color="secondary"
          onClick={() => navigate('create')}
          startIcon={<PersonAddAltOutlinedIcon />}
        />
      </Tooltip>
    ),
    [navigate],
  );

  const handleEventClick = (userId?: number) => {
    userId && navigate(`/applications/user/${CLIENT_ID}/${userId}`);
  };

  return (
    <div data-id="users-list">
      {clientId ? (
        <>
          <ListItems<TUserWithRole, IQueryPropsWithClientId, IUserCardProps>
            heightListItem={height}
            padding={cardHeight.Indent}
            query={query}
            getItems={getUsers}
            RowElement={UserCard}
            searchFormChildren={createUserButton}
            rowElementProps={{
              height: height,
              padding: cardHeight.Indent,
              clientId: clientId,
              onClick: handleEventClick,
              setConfirmModalProps: setModalProps,
            }}
          />
          <ConfirmationModal
            isOpen={modalProps.isOpen}
            onAction={modalProps.onAction}
            onClose={modalProps.onClose}
            title={modalProps.title}
            actionButtonText={modalProps.actionButtonText}
            mainMessage={modalProps.mainMessage}
          />
        </>
      ) : (
        <CustomTypography>Загрузка...</CustomTypography>
      )}
    </div>
  );
};

export const UsersList = connect(mapStateToProps)(UsersListComponent);
