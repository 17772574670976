import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { IQuerySortParams, responseListItems } from '../types';
import { createBaseQuery, createFetchArgs, parseResponse } from '../helpers';
import { TShortClient } from './clients';

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  tagTypes: ['catalog'],
  baseQuery: createBaseQuery('catalog'),
  endpoints: (builder) => ({
    getCatalog: builder.query<responseListItems<TShortClient[]>, IQuerySortParams>({
      query: (query) => createFetchArgs<IQuerySortParams>('', 'GET', query),
      transformResponse: (items: TShortClient[], meta: FetchBaseQueryMeta) =>
        parseResponse<TShortClient[]>(items, meta),
    }),
  }),
});

export const { useLazyGetCatalogQuery } = catalogApi;
