import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  OauthProviderType,
  TEditProvider,
  TOauthProvider,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { isObjectEmpty } from '../../helpers';
import { ScopeChips } from '../ScopeChips';
import { TChips } from './CreateProvider';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { SharedFormProvider } from '../shared/FormProvider';
import { ConfirmationModal } from '../modal/ConfirmationModal';

export type EditProviderParams = {
  external_client_id: string;
  external_client_secret: string;
  authorization_endpoint: string;
  token_endpoint: string;
  issuer: string;
  userinfo_endpoint: string;
  mapping: string;
  scopes: string;
  redirect_uri: string;
};

export type EditProviderInputs = {
  name: string;
  description: string;
  external_client_id: string;
  external_client_secret: string;
  scopes: string;
  avatar: File | null;
  path_to_avatar: string;
  authorization_endpoint: string;
  token_endpoint: string;
  provider_id: string;
  issuer: string;
  userinfo_endpoint: string;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  mapping: string;
};

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  authorization_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  token_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  userinfo_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  mapping: yup.string().max(2000, 'Mapping не может превышать 2000 символов'),
});

export const EditProvider: FC<TEditProvider<TOauthProvider>> = ({
  isOpen,
  close,
  providerToEdit,
  scope,
}) => {
  const methods = useForm<EditProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [chips, setChips] = useState<TChips[]>([]);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
      setChips([]);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<TOauthProvider & { provider_id: string; params: EditProviderParams }>,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TOauthProvider & {
              provider_id: string;
              params: EditProviderParams;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, type, params, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.scopes;

      if (typeof type === 'string' && type in OauthProviderType) {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<EditProviderInputs, 'avatar' | 'scopes' | 'params'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }

            if (field === 'name' || field === 'description') {
              setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
            }
          });
        }
        if (params) {
          (Object.keys(params) as Array<keyof EditProviderParams>).forEach((field) => {
            if (field === 'redirect_uri') {
              return;
            }
            if (field === 'scopes' && params?.[field]) {
              setChips(
                params?.[field]
                  ?.split(' ')
                  .map((scope, index) => ({ value: scope, key: String(index), changing: false })) ||
                  [],
              );
              return;
            }
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
      } else {
        setClipboardModalOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TOauthProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >,
          field,
        ) => {
          if (field === 'scopes') return acc;
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public'
          ) {
            acc[field] = data[field];
            return acc;
          }
          acc[field] = data[field];
          return acc;
        },
        {},
      );

      payload.scopes = chips.map((chip) => chip.value).join(' ');
      if (!isObjectEmpty(payload))
        updateProvider({
          body: {
            ...payload,
            provider_id: providerToEdit.id,
            type: providerToEdit.type,
          },
          client_id: clientId,
        });
    }
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Редактировать способ входа"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<EditProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={updateResult.isLoading}
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            providerToEdit={providerToEdit}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              required: true,
              onChange: () => {
                if (errors.external_client_id) clearErrors('external_client_id');
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Cекретный ключ (client_secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('external_client_secret', {
              required: true,
              onChange: () => {
                if (errors.external_client_secret) clearErrors('external_client_secret');
              },
            })}
            className="custom"
            error={!!errors.external_client_secret}
            helperText={
              errors.external_client_secret ? errors.external_client_secret.message : ''
            }
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Cекретный ключ ресурса
          </CustomTypography>
          {providerToEdit?.type === OauthProviderType.CUSTOM && (
            <>
              <CustomTypography
                className={clsx(
                  'text-14',

                  styles.asterisk,
                  styles['input-title'],
                )}
              >
                Базовый адрес сервера авторизации (issuer)
              </CustomTypography>
              <TextField
                {...register('issuer', {
                  required: true,
                  onChange: () => {
                    if (errors.issuer) clearErrors('issuer');
                  },
                })}
                style={{ marginBottom: 32 }}
                className="custom"
                error={!!errors.issuer}
                helperText={errors.issuer ? errors.issuer.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography
                className={clsx(
                  'text-14',

                  styles.asterisk,
                  styles['input-title'],
                )}
              >
                Адрес авторизации (authorization_endpoint)
              </CustomTypography>
              <TextField
                {...register('authorization_endpoint', {
                  required: true,
                  onChange: () => {
                    if (errors.authorization_endpoint) clearErrors('authorization_endpoint');
                  },
                })}
                className="custom"
                error={!!errors.authorization_endpoint}
                helperText={
                  errors.authorization_endpoint ? errors.authorization_endpoint.message : ''
                }
                fullWidth
                variant="standard"
              />
              <CustomTypography
                className={clsx('text-14', styles['input-subtitle'])}
                color="grey"
              >
                Адрес, на который пользователь переадресовывается для авторизации
              </CustomTypography>
              <CustomTypography
                className={clsx(
                  'text-14',

                  styles.asterisk,
                  styles['input-title'],
                )}
              >
                Адрес выдачи токена (token_endpoint)
              </CustomTypography>
              <TextField
                {...register('token_endpoint', {
                  required: true,
                  onChange: () => {
                    if (errors.token_endpoint) clearErrors('token_endpoint');
                  },
                })}
                className="custom"
                error={!!errors.token_endpoint}
                helperText={errors.token_endpoint ? errors.token_endpoint.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography
                className={clsx('text-14', styles['input-subtitle'])}
                color="grey"
              >
                Ресурс, обеспечивающий выдачу токенов
              </CustomTypography>
              <CustomTypography
                className={clsx(
                  'text-14',

                  styles.asterisk,
                  styles['input-title'],
                )}
              >
                Адрес получения информации о пользователе (userinfo_endpoint)
              </CustomTypography>
              <TextField
                {...register('userinfo_endpoint', {
                  required: true,
                  onChange: () => {
                    if (errors.userinfo_endpoint) clearErrors('userinfo_endpoint');
                  },
                })}
                className="custom"
                error={!!errors.userinfo_endpoint}
                helperText={errors.userinfo_endpoint ? errors.userinfo_endpoint.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography
                className={clsx('text-14', styles['input-subtitle'])}
                color="grey"
              >
                Ресурс, который возвращает информацию о текущем пользователе
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Запрашиваемые разрешения
              </CustomTypography>
              <ScopeChips chips={chips} setChips={setChips} />
              <CustomTypography
                className={clsx('text-14', styles['input-subtitle'])}
                color="grey"
              >
                Перечень разрешений, которые должны быть получены при обращении к поставщику
                идентификации. Для добавления разрешения введите его имя и нажмите Enter.
              </CustomTypography>
            </>
          )}
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Идентификатор провайдера (ProviderId)
          </CustomTypography>
          <TextField
            value={providerToEdit?.id || ''}
            disabled
            className="custom"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconWithTooltip
                    iconType="copy"
                    action={() => {
                      navigator.clipboard.writeText(providerToEdit?.id || '');
                    }}
                  />
                </InputAdornment>
              ),
              classes: {
                disabled: styles['input-wrapper-disabled'],
              },
            }}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор способа входа
          </CustomTypography>
          <ProviderFooter type={providerToEdit?.type as OauthProviderType} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для Oauth-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
