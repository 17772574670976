import { ReactElement } from 'react';
import { Order } from './components/applications/ApplicationsTable';
import { Roles, screenDevices } from './enums';
import {
  MiscProviderType,
  OauthProviderType,
  TMiscProvider,
  TOauthProvider,
} from './redux/services/provider';
import { BACKEND_URL } from './constants';
import { TUserProfile } from './redux/userSlice';
import { TSettings } from './redux/services/client';
import { TProviderColors } from './components/Providers/ProviderColor';
import { AccountTypes, TExternalAccount } from './redux/services/user';
import { EClaimPrivacy } from './components/profile/PublicStatusPopover';
import { TRule } from './redux/services/settings';
import yup from './customYup';

export const getObjectKeys = <T extends Record<string, unknown>>(object: T): Array<keyof T> =>
  <Array<keyof T>>Object.keys(object);

export const isObjectEmpty = (object: Record<string, unknown>): boolean =>
  !Object.keys(object).length;

export const getObjectEntries = <T extends Record<string, never>, K extends keyof T>(
  object: T,
): [keyof T, T[K]][] => Object.entries(object);

export const convertUserProfile = (
  data: Omit<TUserProfile, 'id'> & { sub: string },
): TUserProfile => {
  const result = getObjectKeys(data).reduce(
    (acc: TUserProfile, key) => {
      if (key === 'sub') return acc;
      if (key === 'role') {
        acc.role = data.role;
        return acc;
      }

      acc[key] = (data[key] as any) || '';

      return acc;
    },
    { id: data.sub },
  );

  return result;
};

export const isOwnerOrEditor = (role?: string): boolean =>
  role === Roles.OWNER || role === Roles.EDITOR;

export const isOwner = (role?: string): boolean => role === Roles.OWNER;

export const isAdministrator = (role?: string): boolean =>
  role === Roles.OWNER || role === Roles.ADMIN || role === Roles.EDITOR;

export const isEditor = (role?: string): boolean => role === Roles.EDITOR;

export const getRoleName = (role: string): string => {
  switch (role) {
    case Roles.OWNER:
      return 'Владелец';
    case Roles.ADMIN:
      return 'Администратор приложений';
    case Roles.EDITOR:
      return 'Администратор';
    case Roles.USER:
      return 'Участник';
    case Roles.TRUSTED_USER:
      return 'Доверенный участник';
    default:
      return '';
  }
};

export const getMonthsByYear = (selectedYear: string) => [
  { name: 'январь', days: 31 },
  { name: 'февраль', days: parseInt(selectedYear, 10) % 4 === 0 ? 29 : 28 },
  { name: 'март', days: 31 },
  { name: 'апрель', days: 30 },
  { name: 'май', days: 31 },
  { name: 'июнь', days: 30 },
  { name: 'июль', days: 31 },
  { name: 'август', days: 31 },
  { name: 'сентябрь', days: 30 },
  { name: 'октябрь', days: 31 },
  { name: 'ноябрь', days: 30 },
  { name: 'декабрь', days: 31 },
];

export const isDateError = (birthDate: Date, dataSettings?: TSettings) => {
  const dateNow = new Date();
  if (!dataSettings) return false;
  if (dateNow.getFullYear() - birthDate.getFullYear() < dataSettings.min_age) return true;
  if (dateNow.getFullYear() - birthDate.getFullYear() === dataSettings.min_age) {
    if (dateNow.getMonth() < birthDate.getMonth()) return true;
    if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() < birthDate.getDate())
      return true;
  }
  if (dateNow.getFullYear() - birthDate.getFullYear() > (dataSettings.max_age || 120)) return true;
  if (dateNow.getFullYear() - birthDate.getFullYear() === (dataSettings.max_age || 120)) {
    if (dateNow.getMonth() > birthDate.getMonth()) return true;
    if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() > birthDate.getDate())
      return true;
  }
  return false;
};

export const getMonthByNumber = (month: number): string => {
  switch (month) {
    case 0:
      return 'января';
    case 1:
      return 'февраля';
    case 2:
      return 'марта';
    case 3:
      return 'апреля';
    case 4:
      return 'мая';
    case 5:
      return 'июня';
    case 6:
      return 'июля';
    case 7:
      return 'августа';
    case 8:
      return 'сентября';
    case 9:
      return 'октября';
    case 10:
      return 'ноября';
    case 11:
      return 'декабря';
    default:
      return '';
  }
};

export const getExternalAccountLabel = (account: Omit<TExternalAccount, 'user' | 'user_id'>) => {
  switch (account.type) {
    case AccountTypes.EMAIL:
      return account.email;
    case AccountTypes.KLOUD:
    case AccountTypes.ETHEREUM:
    case AccountTypes.SMS:
    case AccountTypes.QRCODE:
      return account.sub;
    default:
      return (account.given_name || '') + ' ' + (account.family_name || '');
  }
};

export const getImageURL = (path?: string | null): string | undefined => {
  if (!path) return undefined;
  return path?.startsWith('http://') || path?.startsWith('https://')
    ? path
    : `${BACKEND_URL}/${path}`;
};

export const sortList = <T, K extends keyof T>(data: T[], orderBy: K, order: Order): T[] => {
  const arr = [...data].sort((a, b) => {
    const aItem = a[orderBy] || '';
    const bItem = b[orderBy] || '';
    if (order === 'asc') {
      return aItem > bItem ? 1 : -1;
    }
    return aItem > bItem ? -1 : 1;
  });
  return arr;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(func: Function, interval: number): (...args: unknown[]) => void {
  let timeout = false;
  return function (...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const later = function () {
      timeout = false;
    };
    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(later, interval);
    }
  };
}

export const createFullDateString = (date: Date) => {
  return (
    `0${date.getDate()}`.slice(-2) +
    ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г., ${`0${date.getHours()}`.slice(
      -2,
    )}:${`0${date.getMinutes()}`.slice(-2)}`
  );
};

export const formatDate = (value: string | Date): string => {
  const date = new Date(value);

  return (
    `0${date.getDate()}`.slice(-2) +
    ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г.`
  );
};

export const exportToJson = (objectData: Record<string, unknown>, objectName: string) => {
  const filename = objectName;
  const contentType = 'application/json;charset=utf-8;';
  const a = document.createElement('a');
  a.download = filename;
  a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generatePassword = () => {
  // Простая функция для генерации пароля
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  let password = '';
  for (let i = 0; i < 12; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

export const getOffset = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = (offset / 60).toString().padStart(2, '0');
  const offsetMinutes = (offset % 60).toString().padStart(2, '0');
  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

export const replaceJSX = (str: string, find: string, replace: ReactElement) => {
  const arr = str.split(find);
  return arr.flatMap((item, index) => {
    if (index !== arr.length - 1) return [item, replace];
    return item;
  });
};

export const getProviderTitleByType = (type?: OauthProviderType | MiscProviderType) => {
  switch (type) {
    case MiscProviderType.EMAIL:
    case MiscProviderType.CREDENTIALS:
    case MiscProviderType.PHONE:
      return '';
    case MiscProviderType.ETHEREUM:
      return 'ETHEREUM';
    case MiscProviderType.SMS:
      return 'SMS';
    case MiscProviderType.LDAP:
      return 'LDAP';
    case MiscProviderType.ALDPRO:
      return 'ALDPRO';
    case MiscProviderType._1C:
      return '1C';
    case MiscProviderType.IDM:
      return 'IDM';
    case MiscProviderType.QRCODE:
      return 'Device flow';
    default:
      return 'Oauth2';
  }
};

export const checkForNumberEnding = (number: number) => {
  return String(number).endsWith('1') && !String(number).endsWith('11');
};

export const formatSpecChar = (specs: string) => {
  if (specs) {
    return specs
      .split('')
      .reduce((acc: string[], item) => {
        if (item === '[' || item === ']' || item === '/' || item === '-') acc.push(`\\${item}+`);
        else acc.push(item);
        return acc;
      }, [])
      .join('');
  } else {
    return '-~!@_#$"№%:^&?*()|{}+=\\]+\\[+\\/+\\+';
  }
};

export const basicCharacterSet = (settings?: TSettings) =>
  `${
    formatSpecChar(settings?.allowed_special_symbols || '') +
    (settings?.allowed_symbols || 'a-zа-яё')
  }${String(settings?.allowed_symbols || 'a-zа-яё').toUpperCase()}0-9`;

export const validatePassword = (password: string, settings?: TSettings) => {
  if (!settings) return '';
  if (!new RegExp(`^[${basicCharacterSet(settings)}]+$`).exec(password)) {
    if (password.includes(' ')) return 'Пароль не должен содержать пробелы';
    return `Пароль может содержать ${settings.allowed_symbols || 'буквы'}, цифры, спецсимволы ${
      settings.allowed_special_symbols ? formatSpecChar(settings.allowed_special_symbols) : ''
    }`;
  }
  if (password.length < settings.length_char_min)
    return `Используйте не менее ${settings.length_char_min} ${
      checkForNumberEnding(settings.length_char_min) ? 'символа' : 'символов'
    }`;
  if (password.length > settings.length_char_max)
    return `Используйте не более ${settings.length_char_max} ${
      checkForNumberEnding(settings.length_char_max) ? 'символа' : 'символов'
    }`;
  if (
    !new RegExp(
      `^(?=(.*[${formatSpecChar(settings.allowed_special_symbols)}]){${
        settings.spec_char || 0
      }})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  ) {
    const errorForRegular = `Должен содержать не менее ${settings.spec_char}`;
    if (settings.allowed_special_symbols) {
      return `${errorForRegular} из следующих спецсимволов ${formatSpecChar(
        settings.allowed_special_symbols,
      )}`;
    } else {
      return `${errorForRegular} ${
        checkForNumberEnding(settings.spec_char) ? 'спецсимвола' : 'спецсимволов'
      }`;
    }
  }
  if (
    !new RegExp(
      `^(?=(.*[0-9]){${settings.number || 0}})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  )
    return `Пароль должен содержать не менее ${settings.number || 0} ${
      checkForNumberEnding(settings.number) ? 'цифры' : 'цифр'
    }`;
  if (
    !new RegExp(
      `^(?=(.*[${String(settings.allowed_symbols || 'a-zа-яё').toUpperCase()}]){${
        settings.min_uppercase_count || 0
      }})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  ) {
    return `Пароль должен содержать не менее ${settings.min_uppercase_count || 0} ${
      checkForNumberEnding(settings.min_uppercase_count) ? 'заглавной буквы' : 'заглавных букв'
    }`;
  }
  return null;
};

export const getDeclinationByNumber = (number: number, words: string[]) => {
  if (number !== Math.floor(number)) return words[1];
  if (number % 10 === 0 || number % 10 > 4 || (number % 100 > 10 && number % 100 < 20))
    return words[2];
  if (number % 10 === 1) return words[0];
  return words[1];
};

export const generateYearsBetween = (startYear: number, endYear: number) => {
  const years = [];
  for (let i = endYear; i >= startYear; i--) {
    years.push(endYear);
    endYear--;
  }
  return years;
};

export const isUrl = (value: string | undefined) => {
  if (!value) return false;

  const pattern =
    /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

  return pattern.test(value);
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result) || '');
    reader.onerror = (error) => reject(error);
  });

// Type predicate to narrow an unknown error to an object with a string 'message' property
// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
export const isApiErrorWithMessage = (error: unknown): error is { message: string } => {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof error.message === 'string'
  );
};

export const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
  return hash;
};

export const providerParamsToFormData = (
  requestParams: Partial<
    Omit<TMiscProvider | TOauthProvider, 'avatar'> & {
      avatar?: File | null;
      provider_id: string;
    }
  > & { provider_colors?: TProviderColors; provider_title?: string },
) => {
  try {
    const formData = new FormData();

    getObjectKeys(requestParams).forEach((key) => {
      if (key === 'avatar' && requestParams[key] !== undefined) {
        if (requestParams[key]) {
          formData.append(key, requestParams[key] as File);
        }
        return;
      }

      // Удаляем provider_id
      if (key === 'provider_id') {
        return;
      }

      if (key === 'provider_colors') {
        if (requestParams.provider_colors) {
          getObjectKeys(requestParams.provider_colors).forEach((item) =>
            formData.append(item, requestParams.provider_colors?.[item] || ''),
          );
        }
        return;
      }

      if (typeof requestParams[key] === 'boolean') {
        formData.append(key, requestParams[key] ? 'true' : 'false');
        return;
      }

      formData.append(key, requestParams[key]?.toString() || '');
    });

    return formData;
  } catch (e) {
    console.log('providerParamsToFormData error: ' + (e as Error).message);
  }
};

export const showDisplay = (): void => {
  document.body.style.display = 'unset';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const once = function (cb: (params: any[]) => void) {
  let flag = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...params: any[]) => {
    if (flag) return;
    flag = true;
    cb.call(this, params);
  };
};

const hasClaim = (claimsString: string | undefined, claim: string) => {
  const regExp = new RegExp(`^{claim}|\\b${claim}`);
  return !!claimsString && regExp.test(claimsString);
};

export const getClaimPrivacy = (
  claim: string,
  publicProfileClaimsOauth: string | undefined,
  publicProfileClaimsGravatar: string | undefined,
): EClaimPrivacy => {
  if (hasClaim(publicProfileClaimsOauth, claim)) {
    if (hasClaim(publicProfileClaimsGravatar, claim)) return EClaimPrivacy.publicGravatar;
    return EClaimPrivacy.publicOauth;
  }

  return EClaimPrivacy.private;
};

export const getPhoneNumberError = (phoneNumber: string) => {
  const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
  if (!phoneNumber) return 'Укажите номер телефона';
  if (!phoneNumberTest.test(phoneNumber)) {
    return 'Неверный формат номера телефона';
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const countryCode = phoneNumber.slice(0, 1); // +7
  const areaCode = phoneNumber.slice(1, 4); // 912
  const firstPart = phoneNumber.slice(4, 7); // 341
  const secondPart = phoneNumber.slice(7); // 1455

  return `+${countryCode}(${areaCode}) ${firstPart}-${secondPart}`;
};

export const editProfileSchema = (rules: TRule[]): yup.AnyObjectSchema => {
  if (!rules) return yup.object();
  return generateValidationSchema(rules);
};

const generateValidationSchema = (rules: TRule[]) => {
  const schemaFields = rules.reduce((schema, field) => {
    if (!field.active) return schema;

    let fieldValidations = yup.mixed().nullable().notRequired();

    if (field.field_name === 'password') {
      fieldValidations.nullable(false).required();
    }

    // Применение всех активных правил валидации для поля
    field.validations.forEach((validation) => {
      if (validation.active) {
        fieldValidations = fieldValidations.test({
          name: `${field.field_name}-regex`,
          message: validation.error,
          test: (value) => {
            if (field.required && (value === null || value === undefined || value === '')) {
              return true; // пропускаем проверку, если поле обязательное и пустое
            }
            // Применяем regex только если поле не пустое
            if (value === null || value === undefined || value === '') {
              return true; // пропускаем проверку, если поле пустое
            }
            const result = new RegExp(validation.regex).test(value);
            return result;
          },
        });
      }
    });

    return {
      ...schema,
      [field.field_name]: fieldValidations,
    };
  }, {});

  return yup.object().shape(schemaFields).required();
};

export const userParamsToFormData = (
  requestParams: Partial<
    Omit<TUserProfile, 'picture'> & {
      picture?: File | string | null;
      provider_id: string;
      redirect_uri: string;
    }
  > & { [key: string]: any },
) => {
  try {
    const result = Object.keys(requestParams).reduce((acc, key) => {
      if (key === 'picture' && requestParams[key] instanceof File) {
        acc.append(key, requestParams[key] || '');
        return acc;
      }
      if (key === 'custom_fields' && requestParams[key] !== undefined) {
        acc.append(key, JSON.stringify(requestParams[key]));
        return acc;
      }

      acc.append(key, requestParams[key]?.toString() || '');

      return acc;
    }, new FormData());
    return result;
  } catch (e) {
    console.log('userParamsToFormData error: ' + (e as Error).message);
  }
};

export const getScreenSize = (width: number) => {
  switch (true) {
    case width < screenDevices.Mobile: {
      return screenDevices.Mobile;
      break;
    }
    case width < screenDevices.miniTablet: {
      return screenDevices.miniTablet;
      break;
    }
    case width < screenDevices.Tablet: {
      return screenDevices.Tablet;
      break;
    }
    default: {
      return screenDevices.Desktop;
    }
  }
};
