import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { screenDevices } from 'src/enums';

export type TAppSlice = {
  isMenuOpen: boolean;
  isClientPanelOpen: boolean;
  selectedClientId?: string;
  widgetColors: { buttonColor: string; fontColor: string; linkColor: string };
  clientPanelWidth: number;
  isMobile: boolean;
  screenSize: number;
  applicationFormChanged: boolean;
  isNotificationPanelOpen: boolean;
  isSnackbarOpen: boolean;
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
  isEventLogRightPanelOpen: boolean;
  isEventLogFilterOpen: boolean;
  applicationInfoTab: number;
  applicationsTab: string;
  applicationRightPanelTab: number;
  toggleUpdateUsers: boolean;
  profileTab: string;
  selectedTheme: 'dark' | 'white';
};

const initialState: TAppSlice = {
  isMenuOpen: true,
  isClientPanelOpen: false,
  widgetColors: { buttonColor: '#4C6AD4', fontColor: '#fff', linkColor: '#000' },
  clientPanelWidth: 533,
  isMobile: false,
  screenSize: screenDevices.Desktop,
  applicationFormChanged: false,
  isNotificationPanelOpen: false,
  isSnackbarOpen: false,
  isModalOpen: false,
  isChangesUnsaved: false,
  componentPath: '',
  isEventLogRightPanelOpen: false,
  isEventLogFilterOpen: false,
  applicationInfoTab: 0,
  applicationsTab: '/applications',
  applicationRightPanelTab: 0,
  toggleUpdateUsers: false,
  profileTab: '/profile',
  selectedTheme: 'white',
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setClientPanel(state, action: PayloadAction<boolean>) {
      state.isClientPanelOpen = action.payload;
    },

    setSelectedTheme(state, action: PayloadAction<TAppSlice['selectedTheme']>) {
      state.selectedTheme = action.payload;
    },

    setSelectedClientId(state, action: PayloadAction<string | undefined>) {
      state.selectedClientId = action.payload;
    },

    setWidgetColor(state, action: PayloadAction<Partial<TAppSlice['widgetColors']>>) {
      state.widgetColors = { ...state.widgetColors, ...action.payload };
    },

    setClinentPanelWidth(state, action: PayloadAction<number>) {
      state.clientPanelWidth = action.payload;
    },

    setIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },

    setScreenSize(state, action: PayloadAction<number>) {
      state.screenSize = action.payload;
    },

    setApplicationFormChanged(state, action: PayloadAction<boolean>) {
      state.applicationFormChanged = action.payload;
    },

    setIsNotificationPanelOpen(state, action: PayloadAction<boolean>) {
      state.isNotificationPanelOpen = action.payload;
    },

    setIsSnackbarOpen(state, action: PayloadAction<boolean>) {
      state.isSnackbarOpen = action.payload;
    },

    setIsModalOpen(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
    },

    setIsChangesUnsaved(state, action: PayloadAction<boolean>) {
      state.isChangesUnsaved = action.payload;
    },

    setComponentPath(state, action: PayloadAction<string>) {
      state.componentPath = action.payload;
    },

    setIsEventLogRightPanelOpen(state, action: PayloadAction<boolean>) {
      state.isEventLogRightPanelOpen = action.payload;
    },

    setIsEventLogFilterOpen(state, action: PayloadAction<boolean>) {
      state.isEventLogFilterOpen = action.payload;
    },

    setApplicationInfoTab(state, action: PayloadAction<number>) {
      state.applicationInfoTab = action.payload;
    },

    setApplicationsTab(state, action: PayloadAction<string>) {
      state.applicationsTab = action.payload;
    },

    setApplicationRightPanelTab(state, action: PayloadAction<number>) {
      state.applicationRightPanelTab = action.payload;
    },

    setToggleUpdateUsers(state, action: PayloadAction<boolean>) {
      state.toggleUpdateUsers = action.payload;
    },

    setProfileTab(state, action: PayloadAction<string>) {
      state.profileTab = action.payload;
    },
  },
});

export const {
  setClientPanel,
  setSelectedClientId,
  setWidgetColor,
  setClinentPanelWidth,
  setIsMobile,
  setScreenSize,
  setApplicationFormChanged,
  setIsNotificationPanelOpen,
  setIsSnackbarOpen,
  setIsModalOpen,
  setIsChangesUnsaved,
  setComponentPath,
  setIsEventLogRightPanelOpen,
  setIsEventLogFilterOpen,
  setApplicationInfoTab,
  setApplicationsTab,
  setApplicationRightPanelTab,
  setToggleUpdateUsers,
  setProfileTab,
  setSelectedTheme,
} = appSlice.actions;
export default appSlice.reducer;
