import { BACKEND_URL, CLIENT_ID, DASHBOARD_URL } from '../constants';
import { TUserProfile } from '../redux/userSlice';
import {
  btoaRFC7636,
  getAccessToken,
  getUrlParams,
  removeTokenCookies,
  setCodeVerifier,
  sha256,
  stringToArrayBuffer,
} from '../service/auth';

export const sendAuthRequest = async (
  prompt: 'none' | 'consent' | 'select_account' = 'none',
): Promise<void> => {
  const codeVerifier = setCodeVerifier();
  const codeVerifierBase64 = btoaRFC7636(stringToArrayBuffer(codeVerifier));
  const codeChallenge = btoaRFC7636(await sha256(codeVerifierBase64));
  const url =
    encodeURI(BACKEND_URL) +
    '/api/oidc/auth?client_id=' +
    encodeURIComponent(CLIENT_ID) +
    '&response_type=code&scope=openid email phone accounts profile offline_access&redirect_uri=' +
    encodeURI(DASHBOARD_URL) +
    '/code' +
    '&code_challenge=' +
    encodeURIComponent(codeChallenge) +
    '&code_challenge_method=S256' +
    '&prompt=' +
    prompt;

  window.location.href = url;
};

export const getTokensByCode = async (): Promise<{
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: number;
}> => {
  const code = getUrlParams();
  const codeVerifier = window.localStorage.getItem('codeVerifier');
  const codeVerifierBase64 = codeVerifier ? btoaRFC7636(stringToArrayBuffer(codeVerifier)) : '';

  let accessToken = '';
  let idToken = '';
  let refreshToken = '';
  let expiresIn;

  try {
    const body =
      'code=' +
      encodeURIComponent(code) +
      '&code_verifier=' +
      encodeURIComponent(codeVerifierBase64) +
      '&redirect_uri=' +
      encodeURI(DASHBOARD_URL + '/code') +
      '&grant_type=authorization_code';

    const response = await fetch(BACKEND_URL + '/api/oidc/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });
    if (response)
      ({
        access_token: accessToken,
        id_token: idToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
      } = await response.json());
  } catch (e) {
    console.log('getTokenByCode error: ', e);
  }

  return { accessToken, idToken, refreshToken, expiresIn };
};

export const getTokensByRefreshToken = async (
  refreshToken: string,
): Promise<{ accessToken: string; idToken: string; expiresIn: number }> => {
  const body = 'grant_type=refresh_token' + '&refresh_token=' + encodeURIComponent(refreshToken);

  let accessToken = '';
  let idToken = '';
  let expiresIn;

  try {
    const response = await fetch(BACKEND_URL + '/api/oidc/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });

    if (response)
      ({
        access_token: accessToken,
        id_token: idToken,
        expires_in: expiresIn,
      } = await response.json());
  } catch (e) {
    console.log('getTokensByRefreshToken error: ' + e);
  }

  return { accessToken, idToken, expiresIn };
};

export const checkAccessToken = async (token: string): Promise<boolean> => {
  let active;

  try {
    const body = 'token=' + token;

    const response = await fetch(BACKEND_URL + '/api/oidc/token/introspection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    ({ active } = await response.json());
  } catch (e) {
    console.log('checkAccessToken error: ' + e);
  }

  return !!active;
};

export const getUserProfile = async (): Promise<
  (Omit<TUserProfile, 'id'> & { sub: string }) | undefined
> => {
  try {
    const accessToken = await getAccessToken();

    if (accessToken) {
      const response = await fetch(BACKEND_URL + '/api/oidc/me', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
      if (response.ok) return await response.json();
    }
  } catch (e) {
    console.log('getUserProfile error: ' + e);
  }
};

export const sendLogoutRequest = async (): Promise<void> => {
  removeTokenCookies();

  window.location.href =
    BACKEND_URL +
    '/api/oidc/session/end?post_logout_redirect_uri=' +
    DASHBOARD_URL +
    '&client_id=' +
    CLIENT_ID;
};
