import styles from './TopTabsApplication.module.css';
import React, { FC, SyntheticEvent, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { RootState } from '../../redux/rootReducer';
import { CLIENT_ID } from '../../constants';
import { TUserProfile } from '../../redux/userSlice';
import { isOwner } from '../../helpers';
import { setApplicationsTab } from '../../redux/appSlice';
import { useTheme } from '@mui/material';

type TApplicationsProps = {
  role?: TUserProfile['role'];
  applicationsTab: string;
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.userProfile.role,
  applicationsTab: state.app.applicationsTab,
});

const TopTabsApplicationComponent: FC<TApplicationsProps> = ({ role, applicationsTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const theme = useTheme();

  useEffect(() => {
    if (location.endsWith(`/applications`)) {
      dispatch(setApplicationsTab(location));
    }

    if (location.startsWith(`/applications/edit/${CLIENT_ID}/lk`)) {
      dispatch(setApplicationsTab(location));
    }

    if (
      location.startsWith(`/applications/users`) ||
      location.startsWith(`/applications/user/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/edit/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/external-provider/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/email/add/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/email/change/${CLIENT_ID}`)
    ) {
      dispatch(setApplicationsTab(`/applications/users`));
    }

    if (location.startsWith(`/applications/event-log`)) {
      dispatch(setApplicationsTab(location));
    }
  }, [location]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    dispatch(setApplicationsTab(newValue));
    navigate(newValue);
  };

  return (
    <Box className={styles.applications} sx={{ height: 54, display: 'flex', alignItems: 'center' }}>
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{
          sx: {
            background: theme.palette.custom.main,
            height: '1px',
          },
        }}
        value={applicationsTab}
        onChange={handleChange}
      >
        <Tab
          icon={<LayersOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.custom.main,
            },
          }}
          label="Приложения"
          id="0"
          value={'/applications'}
          onClick={() => navigate('/applications')}
        />
        {isOwner(role) && (
          <Tab
            icon={<SettingsOutlinedIcon />}
            iconPosition="start"
            className={styles.tab}
            sx={{
              '&.Mui-selected': {
                color: theme.palette.custom.main,
              },
            }}
            label="Настройки"
            id="1"
            value={`/applications/edit/${CLIENT_ID}/lk`}
            onClick={() => navigate(`/applications/edit/${CLIENT_ID}/lk`)}
          />
        )}
        {isOwner(role) && (
          <Tab
            icon={<PeopleAltOutlinedIcon />}
            iconPosition="start"
            className={styles.tab}
            sx={{
              '&.Mui-selected': {
                color: theme.palette.custom.main,
              },
            }}
            label="Пользователи"
            id="2"
            value={'/applications/users'}
            onClick={() => navigate(`/applications/users`, { state: { clientId: CLIENT_ID } })}
          />
        )}
        <Tab
          icon={<TocOutlinedIcon />}
          iconPosition="start"
          sx={{
            '&.Mui-selected': {
              color: theme.palette.custom.main,
            },
          }}
          className={styles.tab}
          label="Журнал"
          id="3"
          value={'/applications/event-log'}
          onClick={() => navigate('/applications/event-log')}
        />
      </Tabs>
    </Box>
  );
};

export const TopTabsApplication = connect(mapStateToProps)(TopTabsApplicationComponent);
