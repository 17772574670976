import Box from '@mui/material/Box';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';

import React, { FC, MouseEvent, useEffect, useState } from 'react';
import styles from './ApplicationInfo.module.css';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as EditIcon } from '../../icons/Edit.svg';
import { ReactComponent as WithdrawIcon } from '../../icons/Withdraw.svg';
import { ReactComponent as InfoIcon } from '../../icons/Info.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
  TApplication,
  TUser,
  useLazyGetScopesQuery,
  useLazyGetUserCountQuery,
  useDeleteApplicationMutation,
  useRevokeScopesMutation,
  useLazyDeleteSessionByClientQuery,
  useGetApplicationByIdQuery,
  useGetClientByScopesQuery,
} from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { getImageURL, isEditor, isOwner, isOwnerOrEditor } from '../../helpers';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Skeleton from '@mui/material/Skeleton';
import { setApplicationInfoTab, setClientPanel, TAppSlice } from '../../redux/appSlice';
import { ApplicationInfoProfile } from './ApplicationInfoProfile';
import { ApplicationInfoUsers } from './ApplicationInfoUsers';
import { SelectViewType } from '../custom/SelectViewType';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { PROJECT_NAME } from '../../constants';
import { UsersRightPanel } from '../users/old/UsersRightPanel';
import { setUserRoleInApp } from '../../redux/userSlice';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomIcon } from '../custom/CustomIcon';
import { CustomSearchInput } from '../custom/CustomSearchInput';

type TApplicationInfoComponent = {
  userId?: string;
  isMobile: TAppSlice['isMobile'];
  globalRole?: string;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  isMobile: state.app.isMobile,
  globalRole: state.user.userProfile.role,
});
const ApplicationInfoComponent: FC<TApplicationInfoComponent> = ({
  userId,
  isMobile,
  globalRole,
}) => {
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [clientAvatar, setClientAvatar] = useState<null | string>('none');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [revokeScopes] = useRevokeScopesMutation();
  const [deleteApplication] = useDeleteApplicationMutation();
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [scopesData, setScopesData] = useState<
    { scopes?: string; createdAt?: string } | undefined
  >();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getScopes] = useLazyGetScopesQuery();
  const [deleteSessionByClient] = useLazyDeleteSessionByClientQuery();
  const location = useLocation();

  let {
    data: application,
    // eslint-disable-next-line prefer-const
    isLoading: isAppLoading,
    // eslint-disable-next-line prefer-const
    isUninitialized: getAppUnitialized,
  } = useGetApplicationByIdQuery(
    { client_id: clientId, user_id: userId || '' },
    {
      skip: !clientId || !userId,
    },
  );
  ({ data: application = application } = useGetClientByScopesQuery(
    { client_id: clientId, user_id: userId || '' },
    {
      skip:
        getAppUnitialized ||
        isAppLoading ||
        !clientId ||
        !userId ||
        isOwnerOrEditor(application?.role),
    },
  ));

  const isLoading = isAppLoading || !userId;

  useEffect(() => {
    const setScopes = async () => {
      if (userId && clientId) setScopesData((await getScopes({ userId, clientId: clientId })).data);
    };
    setScopes();
  }, [userId, clientId]);

  useEffect(() => {
    setClientAvatar(application?.client.avatar || null);

    if (application?.role) dispatch(setUserRoleInApp(application?.role));

    return () => {
      dispatch(setUserRoleInApp(undefined));
    };
  }, [application]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleDeleteApp = async (clientId: string) => {
    await deleteApplication(clientId);
    navigate('/applications');
    setDeleteModalOpen(false);
  };

  const handleRevokeScopes = async () => {
    await revokeScopes({ userId, clientId: application?.client.client_id });
  };

  const handleDeleteSessionByClient = async () => {
    await deleteSessionByClient({ userId, clientId: application?.client.client_id });
    window.location.reload();
  };

  const handleClosePopover = () => setViewAnchorEl(null);
  return (
    <div className={styles.panel}>
      <div className={styles['panel-top']}>
        <div
          className={styles['app-icon-wrapper']}
          style={{
            backgroundImage: `url(${getImageURL(clientAvatar)})`,
          }}
        >
          {!clientAvatar && <AppIcon fill="#CED0D9" className={styles['app-icon']} />}
        </div>
        <div className={styles['name-wrapper']}>
          <CustomTypography
            className={clsx(
              'header-2-medium',
              'font-golos',

              styles['overflow-ellipsis'],
            )}
            component="div"
          >
            {isLoading ? <Skeleton width={130} /> : application?.client.name}
          </CustomTypography>
          <Popover
            classes={{
              paper: styles.paper,
            }}
            PaperProps={{
              sx: {
                transform: `translateX(${
                  290 - (viewAnchorEl?.offsetWidth || 290)
                }px) translateY(12px) !important`,
              },
            }}
            onClose={handleClosePopover}
            anchorEl={viewAnchorEl}
            open={!!viewAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <CustomPopoverButton
              onClick={() => handleDeleteSessionByClient()}
              startIcon={<QuitIcon className={styles['action-button-icon']} />}
            >
              Завершить свои сеансы
            </CustomPopoverButton>
            {(isOwner(globalRole) || isEditor(application?.role)) && (
              <Link
                style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                to={`/applications/${clientId}`}
              >
                <CustomPopoverButton
                  startIcon={
                    <InfoIcon
                      fill="#fff"
                      className={clsx(styles['action-button-icon'], styles['info-icon'])}
                    />
                  }
                  onClick={() => dispatch(setApplicationInfoTab(0))}
                >
                  Посмотреть профиль
                </CustomPopoverButton>
              </Link>
            )}
            {!isOwner(globalRole) && (
              <>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  startIcon={<WithdrawIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                  onClick={() => handleRevokeScopes()}
                >
                  Отозвать разрешения
                </CustomPopoverButton>
              </>
            )}
            {(isOwner(globalRole) || isEditor(application?.role)) && (
              <>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  startIcon={<PeopleAltOutlinedIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                  onClick={() => dispatch(setApplicationInfoTab(1))}
                >
                  Участники приложения
                </CustomPopoverButton>
                <Link
                  style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                  to={`/applications/edit/${clientId}`}
                >
                  <CustomPopoverButton
                    startIcon={<EditIcon className={styles['action-button-icon']} />}
                  >
                    Настройки
                  </CustomPopoverButton>
                </Link>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  onClick={() => setDeleteModalOpen(true)}
                  startIcon={<BasketIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                >
                  Удалить
                </CustomPopoverButton>
              </>
            )}
          </Popover>
          {!isMobile && (
            <div className={styles['user-buttons']}>
              {isLoading ? (
                <Skeleton variant="rectangular" height={40} width={180} />
              ) : (
                <>
                  <Button
                    onClick={handleOpenPopover}
                    className={clsx('text-14', styles['popover-button'])}
                    variant="custom"
                    color="secondary"
                    endIcon={<CustomIcon Icon={ArrowDown} />}
                  >
                    {isOwnerOrEditor(application?.role) ? 'Вы администратор' : 'Вы участник'}
                  </Button>
                  {(isOwner(globalRole) || isEditor(application?.role)) && application && (
                    <Link
                      className={styles['edit-link']}
                      to={`/applications/edit/${application.client.client_id}`}
                    >
                      <Button
                        data-id="app-edit-button"
                        className={styles['edit-button']}
                        variant="custom"
                        color="secondary"
                        endIcon={<EditIcon />}
                        tabIndex={-1}
                      />
                    </Link>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={styles['mobile-buttons']}>
          <Button
            data-id="app-edit-mobile-button"
            onClick={handleOpenPopover}
            className={clsx('text-14', styles['popover-button'])}
            variant="custom"
            color="secondary"
            endIcon={<CustomIcon Icon={ArrowDown} />}
          >
            {isOwnerOrEditor(application?.role) ? 'Вы администратор' : 'Вы участник'}
          </Button>
          {(isOwner(globalRole) || isEditor(application?.role)) && application && (
            <Link
              className={styles['edit-link']}
              to={`/applications/edit/${application.client.client_id}`}
            >
              <Button
                className={styles['edit-button']}
                variant="custom"
                color="secondary"
                endIcon={<EditIcon />}
                tabIndex={-1}
              />
            </Link>
          )}
        </div>
      )}
      {application?.role && (isOwner(globalRole) || isOwnerOrEditor(application?.role)) ? (
        <ApplicationAdminTabs application={application} scopesData={scopesData} />
      ) : (
        <div style={{ height: '100%', overflow: 'hidden' }}>
          <ApplicationInfoProfile scopesData={scopesData} application={application} />
        </div>
      )}

      <ConfirmationModal
        title="Удалить приложение"
        mainMessage={[
          `Приложение ${application?.client.name} будет удалено.`,
          `Пользователи не смогут войти в ваше приложение, используя аккаунт ${PROJECT_NAME}.`,
        ]}
        additionMessage="Это действие невозможно отменить."
        isOpen={deleteModalOpen}
        onAction={() => {
          if (application?.client) handleDeleteApp(application.client.client_id);
        }}
        onClose={closeDeleteModal}
      />
    </div>
  );
};

type ApplicationAdminTabsProps = {
  application?: TApplication;
  scopes?: string;
  isMobile: TAppSlice['isMobile'];
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  scopesData?: { scopes?: string; createdAt?: string };
  applicationInfoTab: number;
};

const adminTabsStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  isClientPanelOpen: state.app.isClientPanelOpen,
  applicationInfoTab: state.app.applicationInfoTab,
});

const ApplicationAdminTabsComponent: FC<ApplicationAdminTabsProps> = ({
  application,
  scopesData,
  applicationInfoTab,
  isMobile,
  isClientPanelOpen,
}) => {
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const isOpen = Boolean(viewAnchorEl);
  const [selectedUser, setSelectedUser] = useState<{ user: Partial<TUser>; role: string } | null>(
    null,
  );
  const [usersCount, setUsersCount] = useState(0);
  const [getUserCount] = useLazyGetUserCountQuery();
  const [users, setUsers] = useState<({ user: Partial<TUser>; role: string } | undefined)[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const setUsersCountOnStart = async () => {
      const { data } = await getUserCount({
        selectedAppId: application?.client.client_id || '',
        search_string: '',
      });
      setUsersCount(data?.userCount || 0);
    };
    setUsersCountOnStart();
  }, [application]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (typeof newValue === 'number') dispatch(setApplicationInfoTab(newValue));
  };
  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));
  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };

  const onSearch = async (searchString: string) => {
    const { data: countData } = await getUserCount({
      selectedAppId: application?.client.client_id || '',
      search_string: searchString,
    });
    if (countData !== undefined) {
      const { userCount } = countData;
      setUsers(new Array(Number(userCount)).fill(undefined));
    }
  };

  return (
    <>
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{ className: styles['tab-indicator'] }}
        value={applicationInfoTab}
        onChange={handleChange}
      >
        <Tab
          icon={<SettingsApplicationsOutlinedIcon />}
          iconPosition="start"
          label="Профиль"
          id="1"
          classes={{ root: styles.tab }}
        />
        <Tab
          icon={<PeopleAltOutlinedIcon />}
          iconPosition="start"
          label={`Участники ${usersCount}`}
          id="2"
          classes={{ root: styles.tab }}
        />
        <Popover
          classes={{ paper: styles['view-popover-paper'] }}
          onClose={() => setViewAnchorEl(null)}
          anchorEl={viewAnchorEl}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
        </Popover>
        {!isMobile && applicationInfoTab === 1 && (
          <Button
            onClick={(event: MouseEvent<HTMLButtonElement>) => setViewAnchorEl(event.currentTarget)}
            className={styles.view}
            classes={{ endIcon: styles['view-icon'] }}
            endIcon={<ArrowDown fill="#9DA2B3" />}
          >
            <CustomTypography className={clsx('text-14')} color="grey">
              Вид
            </CustomTypography>
          </Button>
        )}
      </Tabs>
      <TabPanel value={applicationInfoTab} index={0}>
        <ApplicationInfoProfile application={application} scopesData={scopesData} />
      </TabPanel>
      <TabPanel value={applicationInfoTab} index={1}>
        <div>
          <div className={styles['search-wrapper']}>
            <CustomSearchInput
              onSearch={onSearch}
              searchParams={{}}
              setSearchValue={setSearchValue}
            />
          </div>

          <div style={{ position: 'relative' }}>
            <ApplicationInfoUsers
              usersFromSearch={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              selectedClientId={application?.client.client_id}
              searchString={searchValue}
            />
            <UsersRightPanel
              userId={selectedUser?.user.id}
              clientId={application?.client.client_id}
            />
          </div>
        </div>
      </TabPanel>
    </>
  );
};

const ApplicationAdminTabs = connect(adminTabsStateToProps)(ApplicationAdminTabsComponent);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div style={index === 0 ? { height: '100%', overflow: 'hidden' } : {}} hidden={value !== index}>
      {value === index && children}
    </div>
  );
}

export const ApplicationInfo = connect(mapStateToProps)(ApplicationInfoComponent);
