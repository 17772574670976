import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { FC } from 'react';
import { LOADING_TEXT } from 'src/constants';
import { formatDate } from 'src/helpers';
import { TShortClient } from 'src/redux/services/clients';
import { CustomTypography } from '../custom/CustomTypography';
import { Card, ICardProps } from '../shared/Card';
import styles from './CatalogCard.module.css';

export interface ICatalogCardProps extends ICardProps {
  items: TShortClient[];
  index: number;
}

const CatalogCardComponent: FC<ICatalogCardProps> = (props) => {
  const { items, index } = props;
  const { avatar, name, description, created_at, domain, client_id } = items[index] || {};

  return (
    <Card
      {...props}
      cardId={client_id}
      avatarUrl={avatar}
      isImage
      onClick={() => window.open(domain, '_blank')}
      content={
        <div className={styles.content}>
          <Box className={styles.mainInfo}>
            <CustomTypography className={styles.title}>
              {name || LOADING_TEXT}
            </CustomTypography>
            <CustomTypography color="grey" className={clsx('text-12')}>
              {formatDate(created_at) || LOADING_TEXT}
            </CustomTypography>
          </Box>
          {description && (
            <Box className={styles.addInfo}>
              <CustomTypography color="grey" className={clsx('text-12')}>
                Описание:
              </CustomTypography>
              <CustomTypography className={clsx('text-12', styles.description)}>{description || LOADING_TEXT}</CustomTypography>
            </Box>
          )}
        </div>
      }
    />
  );
};

export const CatalogCard = (CatalogCardComponent);
