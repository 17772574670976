import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export enum SmsCodeTypes {
  authorization = 'authorization',
  addPhone = 'addPhone',
}

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/sms/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkPhoneNumberAvialability: builder.query<{ isAvailable: boolean }, string>({
      query: (phoneNumber) =>
        `${BACKEND_URL}/api/v1/verification/check?type=sms&value=${encodeURIComponent(
          phoneNumber,
        )}`,
    }),

    sendSmsCode: builder.mutation<
      { message?: string },
      {
        uid?: string;
        phoneNumber: string;
        providerId: string;
        type: SmsCodeTypes;
        userId?: string;
        name?: string;
        resend?: boolean;
        rebind?: boolean;
      }
    >({
      query: ({ uid, phoneNumber, type, userId, name, resend, rebind, providerId }) => {
        return {
          url: BACKEND_URL + '/api/v1/verification/code',
          body: JSON.stringify({
            type: 'sms',
            code_type: type,
            phone_number: '+7' + phoneNumber,
            provider_id: providerId,
            uid,
            user_id: userId,
            name,
            resend,
            rebind,
          }),
          headers: {
            'Content-Type': 'application/json ',
          },
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useLazyCheckPhoneNumberAvialabilityQuery, useSendSmsCodeMutation } = smsApi;
