import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './Modal.module.css';

interface IModalInfo {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
}

export const ModalInfo: FC<IModalInfo> = ({ isOpen, onClose, children, title }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={clsx(styles['modal-container'])}>
        <div style={{ display: 'flex' }}>
          {title && (
            <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
              {title}
            </CustomTypography>
          )}
          <IconButton onClick={onClose} style={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
};
