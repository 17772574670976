import React, { FC } from 'react';
import styles from './EmailTemplate.module.css';
import ListItem from '@mui/material/ListItem';
import { CustomTypography } from './custom/CustomTypography';
import { IconWithTooltip } from './shared/IconWithTooltip';

export enum EEmailAction {
  account_create = 'account_create',
  confirmation_code = 'confirmation_code',
  confirmation_link = 'confirmation_link',
  password_change = 'password_change',
}

export interface IEmailTemplate {
  action: EEmailAction;
  title: string;
  content: string;
}

type EmailTemplateProps = {
  template: IEmailTemplate;
  onClick: () => void;
};

export const EmailTemplate: FC<EmailTemplateProps> = ({ template, onClick }) => {
  return (
    <ListItem className={styles['email-item']}>
      <div className={styles['email-content']}>
        <div className={styles['email-head']}>
          <CustomTypography className={styles['email-title']}>{template.title}</CustomTypography>
          <CustomTypography color="grey" className={styles['email-field']}>
            {template.action}
          </CustomTypography>
        </div>
        <IconWithTooltip iconType="edit" action={() => onClick()} />
      </div>
    </ListItem>
  );
};
