import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TApplication, TClient } from './client';
import { TUserProfile } from '../userSlice';
import { userParamsToFormData } from '../../helpers';

export type TUserFieldsToUpdate = Pick<
  TUserProfile,
  | 'birthdate'
  | 'email'
  | 'nickname'
  | 'phone_number'
  | 'given_name'
  | 'family_name'
  | 'custom_fields'
> & {
  picture?: File | string | null;
  user_id: string;
};

export const ownerApi = createApi({
  reducerPath: 'ownerApi',
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/owner`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateUserByOwner: builder.mutation<void, { user_id: string; data: Partial<TUserProfile> }>({
      query: ({ user_id, data }) => {
        return {
          url: `${BACKEND_URL}/api/v1/users/${user_id}`,
          method: 'PUT',
          body: userParamsToFormData(data),
        };
      },
    }),

    addEmailByOwner: builder.mutation<void, { user_id: string | number; email: string }>({
      query: ({ user_id, ...body }) => {
        return {
          url: `/users/email/${user_id}`,
          method: 'POST',
          body,
        };
      },
    }),

    getAllApplications: builder.query<
      TApplication[],
      {
        user_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: (body) => {
        return {
          url: `/get_all_clients/${body.user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        };
      },
    }),

    getAllApplicationsCount: builder.query<number, { userId: string; searchString?: string }>({
      query: ({ userId, searchString }) => {
        return {
          url: `/count_all_clients/${userId}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { search_string: searchString },
        };
      },
    }),

    blockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `${BACKEND_URL}/api/v1/users/${body.checked_ids ? body.checked_ids[0] : 0}/block`,
          method: 'PUT',
          body,
        };
      },
    }),

    unblockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `${BACKEND_URL}/api/v1/users/${body.checked_ids ? body.checked_ids[0] : 0}/unblock`,
          method: 'PUT',
          body,
        };
      },
    }),

    deleteUsersInfo: builder.mutation<
      { apps: { client: TClient; onlyEditor: boolean }[]; users_ids: number[] },
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({ url: `delete-users-info`, method: 'POST', body }),
    }),

    deleteUsers: builder.mutation<
      { errors: string[] },
      { apps_ids?: string[] | null; delete_apps_with_user?: boolean; checked_ids: number[] }
    >({
      query: (body) => ({
        method: 'DELETE',
        url: `${BACKEND_URL}/api/v1/users/${body.checked_ids ? body.checked_ids[0] : 0}`,
      }),
    }),

    deleteUsersSessions: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({
        method: 'DELETE',
        body,
        url: `${BACKEND_URL}/api/v1/users/${body.checked_ids ? body.checked_ids[0] : 0}/sessions`,
      }),
    }),

    createUser: builder.mutation<
      void,
      Partial<Omit<TUserProfile, 'picture'> & { picture: File | null }>
    >({
      query: (body) => ({
        method: 'POST',
        body: userParamsToFormData(body),
        url: `${BACKEND_URL}/api/v1/users`,
      }),
    }),

    changePasswordByOwner: builder.mutation<
      void,
      {
        newPassword: string;
        userId: string;
      }
    >({
      query: ({ newPassword, userId }) => {
        return {
          url: `${BACKEND_URL}/api/v1/users/${userId}/password`,
          method: 'PUT',
          body: 'password=' + newPassword,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
    }),
  }),
});

export const {
  useUpdateUserByOwnerMutation,
  useAddEmailByOwnerMutation,
  useLazyGetAllApplicationsCountQuery,
  useLazyGetAllApplicationsQuery,
  useBlockUsersMutation,
  useUnblockUsersMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useChangePasswordByOwnerMutation,
  useCreateUserMutation,
} = ownerApi;
