import React, { FC } from 'react';
import { connect } from 'react-redux';
import { cardHeight, Order, screenDevices } from 'src/enums';
import { useLazyGetCatalogQuery } from 'src/redux/services/catalog';
import { TShortClient } from 'src/redux/services/clients';
import { IQuerySortParams } from 'src/redux/types';
import { RootState } from '../../redux/store';
import { ListItems } from '../shared/listElements';
import { CatalogCard, ICatalogCardProps } from './CatalogCard';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
});

interface ICatalogProps {
  screenSize: number;
}

const CatalogComponent: FC<ICatalogProps> = ({ screenSize }) => {
  const [getCatalog] = useLazyGetCatalogQuery();
  const query = (offset: number, search?: string): IQuerySortParams => {
    return {
      sortBy: 'created_at',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      search: search || '',
    };
  };

  const getCardHeight = (screenSize: number) => {
    switch (screenSize) {
      case screenDevices.Desktop:
        return 100;
      default:
        return 80;
    }
  };

  const height = getCardHeight(screenSize);

  return (
    <div data-id="catalog">
      <ListItems<TShortClient, IQuerySortParams, ICatalogCardProps>
        heightListItem={height}
        padding={cardHeight.Indent}
        query={query}
        getItems={getCatalog}
        RowElement={CatalogCard}
        rowElementProps={{
          height,
          padding: cardHeight.Indent,
        }}
      />
    </div>
  );
};

export const Catalog = connect(mapStateToProps)(CatalogComponent);
