import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { IQuerySortParams, responseListItems } from '../types';
import { createBaseQuery, createFetchArgs, parseResponse } from '../helpers';

export type TLogger = {
  id: number;
  date: string;
  level: string;
  ip_address: string;
  device: string;
  user_id: number;
  client_id: string;
  event: string;
  description: string;
  name: string;
  avatar: string;
};

export const loggerApi = createApi({
  reducerPath: 'loggerApi',
  tagTypes: ['Logger'],
  baseQuery: createBaseQuery('logs'),

  endpoints: (builder) => ({
    getEventsLog: builder.query<responseListItems<TLogger[]>, IQuerySortParams>({
      query: (query) => createFetchArgs<IQuerySortParams>('', 'GET', query),
      transformResponse: (logs: TLogger[], meta: FetchBaseQueryMeta) =>
        parseResponse<TLogger[]>(logs, meta),
    }),
  }),
});

export const { useGetEventsLogQuery, useLazyGetEventsLogQuery } = loggerApi;
