import React, { memo } from 'react';
import Button from '@mui/material/Button';
import styles from './ActionButtons.module.css';
import clsx from 'clsx';

interface IActionButtonsProps {
  handleCancel: () => void;
  confirmTitle: string;
  handleConfirm: () => void;
  disabled?: boolean;
}
const ActionButtonsComponent = ({
  handleCancel,
  confirmTitle,
  handleConfirm,
  disabled,
}: IActionButtonsProps) => {
  return (
    <div>
      <div className={clsx(styles.buttonWrapper)}>
        <Button data-id={'cancel-button-form'} onClick={handleCancel} variant="custom" color="secondary">
          Отмена
        </Button>
        <Button
          data-id={'confirm-button-form'}
          style={{ marginLeft: 24 }}
          disabled={disabled}
          variant="custom"
          type="submit"
          onClick={handleConfirm}
        >
          {confirmTitle}
        </Button>
      </div>
    </div>
  );
};

/**
 * Компонент ActionButtons отображает две кнопки: "Отмена" и "Подтвердить".
 * 
 * @component
 * @param handleCancel - Функция, вызываемая при нажатии на кнопку "Отмена".
 * @param confirmTitle - Текст, отображаемый на кнопке "Подтвердить".
 * @param handleConfirm - Функция, вызываемая при нажатии на кнопку "Подтвердить".
 * @param disabled - Флаг, определяющий, должна ли кнопка "Подтвердить" быть отключена.
 */
export const ActionButtons = memo(ActionButtonsComponent);
