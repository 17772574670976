import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ThemeColors } from './Theme';

interface CustomMuiIconProps extends SvgIconProps {
  Icon: React.ElementType<SvgIconProps>;
  customColor?: ThemeColors;
  className?: string;
}

export const CustomMuiIcon: React.FC<CustomMuiIconProps> = ({
  Icon,
  customColor,
  className
}) => {
  const theme = useTheme();

  const getColor = () => {
    if (customColor === 'grey') return theme.palette.custom.mainGrey;
    if (customColor === 'error') return theme.palette.custom.error;
    if (customColor === 'link') return theme.palette.custom.link;
    return theme.palette.custom.main;
  };

  return <Icon className={className} sx={{ color: getColor() }} />
};
