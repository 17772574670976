import React, { FC, useState } from 'react';
import styles from './RuleValidation.module.css';
import ListItem from '@mui/material/ListItem';
import {
  TRuleValidation,
  useAddRuleValidationToRuleMutation,
  useDeleteRuleValidationMutation,
  useRemoveRuleValidationFromRuleMutation,
} from '../../redux/services/settings';
import { Checkbox } from '@mui/material';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { CustomTypography } from '../custom/CustomTypography';

type TRuleValidationField = {
  profile: TRuleValidation;
  onEdit: () => void;
  checkBox: boolean;
  field_name: string;
};

export const RuleValidation: FC<TRuleValidationField> = ({
  profile,
  onEdit,
  checkBox,
  field_name,
}) => {
  const [deleteRuleValidation] = useDeleteRuleValidationMutation();
  const [addRuleValidationToRule] = useAddRuleValidationToRuleMutation();
  const [removeRuleValidationFromRule] = useRemoveRuleValidationFromRuleMutation();
  const [saveCheckBox, setCheckBox] = useState<boolean>(checkBox);

  const handleDelete = async () => {
    await deleteRuleValidation(profile.id);
  };

  const handleCheckBox = async () => {
    setCheckBox(!saveCheckBox);
    if (!saveCheckBox) {
      addRuleValidationToRule({ field_name, id: profile.id });
    } else {
      removeRuleValidationFromRule({ field_name, id: profile.id });
    }
  };

  return (
    <ListItem className={`${styles['rule-item']} ${!profile.active ? styles['inactive'] : ''}`}>
      <div className={styles['rule-content']}>
        <div className={styles['rule-head']}>
          <Checkbox checked={saveCheckBox} onClick={handleCheckBox} />
          <CustomTypography className={styles['rule-title']}>{profile.title}</CustomTypography>
        </div>
        <div className={styles['rule-icons']}>
          <IconWithTooltip iconType="edit" action={onEdit} />
          <IconWithTooltip iconType="delete" action={handleDelete} placement="bottom-end" />
        </div>
      </div>
    </ListItem>
  );
};
