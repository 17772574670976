import Box from '@mui/material/Box';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { FC, useRef } from 'react';
import { TLogger } from '../../redux/services/logger';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './EventLogInfo.module.css';

interface IEventLogInfoProps {
  selectedEvent: TLogger | null;
}

const EventLogInfoComponent: FC<IEventLogInfoProps> = ({ selectedEvent }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box 
      data-id="event-log-info" 
      ref={ref} 
      className={clsx(styles.eventBlock)}
    >
      <CustomTypography className={clsx('header-2-medium', 'font-golos', styles.eventName)}>
        {selectedEvent?.event}
      </CustomTypography>
      {selectedEvent?.date && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.eventTitle)}>
            Дата и время
          </CustomTypography>
          <CustomTypography color="secondary" className={clsx('text-14', styles.eventText)}>
            {selectedEvent?.date ? format(new Date(selectedEvent.date), 'y-MM-dd HH:mm:ss') : ''}
          </CustomTypography>
        </>
      )}
      {selectedEvent?.client_id && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.eventTitle)}>
            Приложение
          </CustomTypography>
          <CustomTypography color="secondary" className={clsx('text-14', styles.eventText)}>
            {selectedEvent?.client_id}
          </CustomTypography>
        </>
      )}
      {selectedEvent?.user_id && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.eventTitle)}>
            Пользователь
          </CustomTypography>
          <CustomTypography color="secondary" className={clsx('text-14', styles.eventText)}>
            {selectedEvent?.user_id}
          </CustomTypography>
        </>
      )}
      {selectedEvent?.ip_address && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.eventTitle)}>
            Местоположение
          </CustomTypography>
          <CustomTypography color="secondary" className={clsx('text-14', styles.eventText)}>
            {selectedEvent?.ip_address?.replace('::ffff:', '')}
          </CustomTypography>
        </>
      )}
      {selectedEvent?.device && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.eventTitle)}>
            Устройство
          </CustomTypography>
          <CustomTypography color="secondary" className={clsx('text-14', styles.eventText)}>
            {selectedEvent?.device}
          </CustomTypography>
        </>
      )}
      {selectedEvent?.description && (
        <>
          <CustomTypography className={clsx('text-15-medium', styles.title)}>
            Описание
          </CustomTypography>
          {selectedEvent?.description.split('::').map((str, key) => (
            <CustomTypography color="secondary" key={key} className={clsx('text-14', styles.eventText)}>
              {str}
            </CustomTypography>
          ))}
        </>
      )}
    </Box>
  );
};

export const EventLogInfo = (EventLogInfoComponent);
