import React, { FC, useState } from 'react';
import { cardHeight, Order } from 'src/enums';
import { TLogger, useLazyGetEventsLogQuery } from 'src/redux/services/logger';
import { IQuerySortParams } from 'src/redux/types';
import { ModalInfo } from '../modal/ModalInfo';
import { ListItems } from '../shared/listElements';
import { CardEventLog, ICardEventLogProps } from './EventLogCard';
import { EventLogInfo } from './EventLogInfo';
import { useLocation } from 'react-router-dom-v5-compat';

const EventLogComponent: FC = () => {
  const location = useLocation();
  const { userId }: { userId: string } = location.state || {};
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<TLogger | null>(null);
  const [getEventsLog] = useLazyGetEventsLogQuery();

  const query = (offset: number, search?: string): IQuerySortParams => {
    const queryParams: IQuerySortParams = {
      sortBy: 'date',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      search: search || '',
    };

    if (userId) {
      queryParams.filter = JSON.stringify({ user_id: parseInt(userId, 10) });
    }

    return queryParams;
  };

  const openModal = (value: TLogger) => {
    setSelectedEvent(value);
    setIsOpen(true);
  };

  return (
    <div data-id="event-logs">
      <ListItems<TLogger, IQuerySortParams, ICardEventLogProps>
        heightListItem={cardHeight.Desktop}
        padding={cardHeight.Indent}
        query={query}
        getItems={getEventsLog}
        RowElement={CardEventLog}
        rowElementProps={{
          height: cardHeight.Desktop,
          padding: cardHeight.Indent,
          openModal: openModal,
          isUserSpecific: !!userId,
        }}
      />
      <ModalInfo isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <EventLogInfo selectedEvent={selectedEvent} />
      </ModalInfo>
    </div>
  );
};

export const EventLog = EventLogComponent;
