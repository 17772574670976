import { FunctionComponent } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const profileScope = {
  description: 'Просмотр имени, фото и даты рождения',
  icon: BadgeOutlinedIcon,
};

const emailScope = {
  description: 'Просмотр адреса электронной почты',
  icon: AlternateEmailOutlinedIcon,
};

const phoneScope = {
  description: 'Просмотр номера телефона',
  icon: PhoneIphoneOutlinedIcon,
};

const offlineAccessScope = {
  description: 'Приложение получит доступ к вашим данным в любое время',
  icon: HistoryOutlinedIcon,
};

const accountsScope = {
  description: 'Просмотр аккаунтов социальных сетей, добавленных в профиль',
  icon: GroupsOutlinedIcon,
};

const idScope = {
  description: 'Просмотр вашего идентификатора',
  icon: PersonOutlineOutlinedIcon,
};

const unknownScope =  (scope: string) =>{
  return {
    description: `Неизвестное разрешение: ${scope}`,
    icon: ErrorOutlineOutlinedIcon,
  }
};

/**
 * Возвращает объект, содержащий описание и иконку для указанного scope.
 * @param {string} scope - Название scope, для которого нужно получить свойства ( profile | email | phone | offline_access | accounts | openid).
 */
export const getScopeProps = (
  scope: string,
): { description: string; icon: FunctionComponent } => {
  switch (scope) {
    case 'profile':
      return profileScope;
    case 'email':
      return emailScope;
    case 'phone':
      return phoneScope;
    case 'offline_access':
      return offlineAccessScope;
    case 'accounts':
      return accountsScope;
    case 'openid':
      return idScope;
    default:
      return unknownScope(scope);
  }
};
