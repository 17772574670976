import React, { useState } from 'react';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from '../../profile/EditProfile.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import { TCustomFields, TUserProfile, TUserSlice } from '../../../redux/userSlice';
import { checkIdentifier } from '../../../requests/user';
import { editProfileSchema, getMonthsByYear, isDateError } from '../../../helpers';
import { setIsModalOpen, setIsChangesUnsaved } from '../../../redux/appSlice';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProfileFields } from '../../profile/ProfileFields';
import { EditProfileInputs } from '../../profile/EditProfile';
import { useCreateUserMutation } from '../../../redux/services/owner';
import { useLazyCheckEmailAvialabilityQuery } from '../../../redux/services/mail';
import { useLazyCheckPhoneExistsQuery } from '../../../redux/services/phone';
import {
  useGetProfileFieldsQuery,
  useGetRulesQuery,
  useGetSettingsQuery,
} from '../../../redux/services/settings';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from 'src/components/modal/ConfirmationModal';
import { CLIENT_ID } from 'src/constants';

export type UserInputs = EditProfileInputs & {
  password: string;
  email: string;
  phone_number: string;
};

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  userId: state.user.userProfile.id,
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TCreateUserComponent = {
  userProfile: TUserSlice['userProfile'];
  userId?: string;
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const CreateUserComponent: FC<TCreateUserComponent> = ({
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const [checkIdentifierLoading, setCheckIdentifierLoading] = useState(false);
  const { data: profileFields } = useGetProfileFieldsQuery();

  const { data: dataSettings, refetch: refetchSettings } = useGetSettingsQuery();
  const { data: rules, refetch: refetchRules } = useGetRulesQuery();
  useEffect(() => {
    refetchRules();
    refetchSettings();
  }, []);

  const methods = useForm<UserInputs & TCustomFields>({
    resolver: yupResolver(editProfileSchema(rules || [])),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;
  const watchBirthYear = watch('birthYear');
  const dispatch = useDispatch();
  const months = getMonthsByYear(watchBirthYear);
  const navigate = useNavigate();
  const [createUser, { isLoading: createUserLoading }] = useCreateUserMutation();
  const [checkEmailAvialability, { isFetching: checkEmailFetching }] =
    useLazyCheckEmailAvialabilityQuery();
  const [checkPhoneExists, { isFetching: checkPhoneExistsFetching }] =
    useLazyCheckPhoneExistsQuery();

  useEffect(() => {
    if (dataSettings) {
      const date = new Date();
      setValue('birthYear', String(date.getFullYear() - dataSettings.min_age), {
        shouldDirty: false,
      });
      setValue('birthMonth', months[date.getMonth()]?.name, { shouldDirty: false });
      setValue('birthDay', String(date.getDate()), { shouldDirty: false });
    }
  }, [dataSettings]);

  const onSubmit: SubmitHandler<UserInputs & TCustomFields> = async (data) => {
    try {
      const month = months.findIndex(({ name }) => name === data.birthMonth);
      const birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));

      if (isDateError(birthdate, dataSettings)) return;

      const customFields =
        profileFields?.filter((key) => key.type === 'custom' && key.active) || [];
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<Omit<TUserProfile, 'picture'> & { picture: File | null } & TCustomFields>,
          field,
        ) => {
          if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
            return acc;
          }

          if (field === 'phone_number' && data.phone_number) {
            acc.phone_number = data.phone_number;
            return acc;
          }

          if (field === 'picture') {
            if (typeof data.picture !== 'string') {
              acc.picture = data.picture;
              return acc;
            }

            return acc;
          }

          if (customFields.find((cf) => cf.field === field)) {
            acc.custom_fields = acc.custom_fields || {};
            acc.custom_fields[field] = data[field];
            return acc;
          }

          if (data[field]) acc[field] = data[field];

          return acc;
        },
        {},
      );

      if ('birthDay' in dirtyFields || 'birthYear' in dirtyFields || 'birthMonth' in dirtyFields)
        payload.birthdate = (birthdate as unknown as Date).toISOString();

      if ('login' in dirtyFields) {
        setCheckIdentifierLoading(true);
        const isAvailable = await checkIdentifier(data.login);
        setCheckIdentifierLoading(false);
        if (isAvailable) {
          setError('login', {
            type: 'api',
            message: 'Уже существует',
          });
          return;
        }
      }

      if ('phone_number' in dirtyFields && data.phone_number) {
        const response = await checkPhoneExists(data.phone_number).unwrap();

        if (!response.isAvailable) {
          setError('phone_number', {
            type: 'api',
            message: 'Номер телефона уже используется другим пользователем',
          });
          return;
        }
      }

      if ('email' in dirtyFields && data.email) {
        const response = await checkEmailAvialability(data.email).unwrap();

        if (!response.isAvailable) {
          setError('email', {
            type: 'api',
            message: 'Почтовый адрес уже используется',
          });
          return;
        }
      }

      if (dataSettings) {
        const loginFields = dataSettings.allowed_login_fields.split(' ');
        const ok = loginFields.some((field) => payload[field] && payload[field] !== '');
        if (!ok) {
          let message = 'Необходимо заполнить хотя бы один идентификатор';
          if (loginFields.length === 1) {
            message = 'Необходимо заполнить поле';
          }
          loginFields.forEach((field) => {
            setError(field, {
              type: 'required',
              message,
            });
          });
          return;
        }
      }

      await createUser(payload).unwrap();
      navigate('/applications/users', { state: { clientId: CLIENT_ID }});
      dispatch(setIsChangesUnsaved(false));
    } catch (e) {
      console.log('error:', e);
    }
  };

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  return (
    <div className={'wrapper-scroll'}>
      <div className={'content'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
            <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
              Создать пользователя
            </CustomTypography>
            <ProfileFields
              isLoading={
                createUserLoading ||
                checkEmailFetching ||
                checkPhoneExistsFetching ||
                checkIdentifierLoading
              }
              createUser={true}
            />
          </form>
        </FormProvider>

        <ConfirmationModal
          title="Сохранение изменений"
          mainMessage={["Изменения не сохранены. Продолжить без сохранения?"]}
          actionButtonText="Продолжить"
          isOpen={isModalOpen}
          onAction={() => {
            relocation();
          }}
          onClose={() => dispatch(setIsModalOpen(false))}
        />
      </div>
    </div>
  );
};

export const CreateUser = connect(mapStateToProps)(CreateUserComponent);
