import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import {
  IQueryIdProps,
  IQueryPropsWithUserId,
  IQuerySortParams,
  responseListItems,
  TQueryId,
} from '../types';
import { createBaseQuery, createFetchArgs, parseResponse } from '../helpers';
import { TShortClient } from './clients';
import { Roles } from 'src/enums';

export type TScope = {
  id: number;
  client_id: string;
  scopes: string[];
  created_at: string;
  client: TShortClient;
};

export type TUser = {
  family_name: string;
  given_name: string;
  nickname: string;
  picture: string;
  id: number;
  blocked: boolean;
  deleted?: string;
};

export type TUserWithRole = {
  user: TUser;
  role: Roles;
};

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: createBaseQuery('users'),
  endpoints: (builder) => ({
    getUserScopes: builder.query<responseListItems<TScope[]>, IQueryPropsWithUserId>({
      query: (params) =>
        createFetchArgs<IQuerySortParams>(`${params.userId}/scopes`, 'GET', params.query),
      transformResponse: (scopes: TScope[], meta: FetchBaseQueryMeta) =>
        parseResponse<TScope[]>(scopes, meta),
    }),
    revokeScopes: builder.mutation<boolean, IQueryIdProps>({
      query: (params) =>
        createFetchArgs<{ client_id: string }>(`${params.userId}/scopes`, 'DELETE', {
          client_id: params.clientId,
        }),
    }),
    deleteUser: builder.mutation({
      query: (params: TQueryId) => createFetchArgs<TQueryId>(params.id, 'DELETE'),
    }),
    blockUser: builder.mutation({
      query: (params: TQueryId) => createFetchArgs<TQueryId>(`${params.id}/block`, 'PUT'),
    }),
    unblockUser: builder.mutation({
      query: (params: TQueryId) => createFetchArgs<TQueryId>(`${params.id}/unblock`, 'PUT'),
    }),
  }),
});

export const {
  useGetUserScopesQuery,
  useLazyGetUserScopesQuery,
  useRevokeScopesMutation,
  useDeleteUserMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
} = usersApi;
