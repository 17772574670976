import { Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { FC } from 'react';
import { TSentry } from 'src/redux/services/client';
import { useEditSettingsMutation } from 'src/redux/services/settings';
import { CustomTypography } from './custom/CustomTypography';
import styles from './EditSentry.module.css';
import { BaseSidePanel } from './sidePanel/BaseSidePanel';

type TEditSentry = {
  isOpen: boolean;
  close: () => void;
  params: TSentry;
};

/**
 * Панель редактирования настроек Sentry
 */
export const EditSentry: FC<TEditSentry> = ({ isOpen, close, params }) => {
  const [sentry, setSentry] = React.useState<TSentry>(params);
  const [editSettings] = useEditSettingsMutation();

  const handleChange = (field: keyof TSentry) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = field === 'enabled' ? event.target.checked : event.target.value;
    setSentry({ ...sentry, [field]: value });
  };

  const handleSave = async () => {
    try {
      await editSettings({
        sentry,
      }).unwrap();
      close();
    } catch (error) {
      console.error('Failed to save the sentry:', error);
    }
  };

  return (
    <BaseSidePanel
      handleClosePanel={close}
      isOpenPanel={isOpen}
      title="Настроить Sentry"
      description="Настройте отправку трассировок и ошибок в Sentry"
      isNoBackdrop={false}
      handleConfirm={handleSave}
    >
      <div className={styles.container}>
        <Box className={styles.inputWrapper}>
          <CustomTypography className={clsx('text-14', styles.label)}>DSN</CustomTypography>
          <TextField
            variant="outlined"
            fullWidth
            value={sentry.dsn}
            onChange={handleChange('dsn')}
          />
          <CustomTypography className={clsx('text-14', styles.description)} color="grey">
            DSN — это уникальный ключ, который выдается для каждого проекта в Sentry
          </CustomTypography>
          <Switch checked={sentry.enabled} onChange={handleChange('enabled')} color="primary" />
        </Box>
        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-top'])}>
          ID пользователя
        </CustomTypography>
        <TextField
          variant="outlined"
          fullWidth
          value={sentry.user_id}
          onChange={handleChange('user_id')}
        />
        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-top'])}>
          ID пользователя, по действиям которого нужно отправлять трассировки и ошибки
        </CustomTypography>
      </div>
    </BaseSidePanel>
  );
};
